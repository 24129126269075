import React from 'react';
import { Divider } from '@mui/material';
import DrawerHeader from 'pages/Rewards/components/DrawerHeader';


export default function NoteTakerWrapper({
  title,
  children,
  setIsDrawerOpen,
}: {
  title?: string;
  children: React.ReactNode;
  setIsDrawerOpen?: (isDrawerOpen: boolean) => void;
}) {
  return (
    <>
      <DrawerHeader
        title={title || 'Record Session'}
        onClose={() => setIsDrawerOpen && setIsDrawerOpen(false)}
        showBackIcon={false}
        showCloseIcon={setIsDrawerOpen !== undefined}
      />
      <Divider />
      {children}
    </>
  );
}
