import React, { useEffect, useState, useRef } from 'react';
import { Snackbar, Alert, Box } from '@mui/material';
import Theme from 'theme';
import { MicNone } from '@mui/icons-material';
import { trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';

const intervalForSilenceDetection = 10000;

function RecordingIndicator({
  volumeLevel,
}: {
  volumeLevel: number;
}) {
  const clamped = Math.min(1, Math.max(0, volumeLevel / 100));
  const size = 18 + clamped * 18;

  return (
    <Box
      height={36}
      width={36}
      display='flex'
      alignItems='center'
      justifyContent='center'
      position='relative'
    >
      <Box
        sx={{
          width: `${size}px`,
          height: `${size}px`,
          transition: 'all 0.15s ease-out',
          backgroundColor: Theme.custom.colors.lightSuccessMain,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <MicNone sx={{ color: 'white', zIndex: 1 }} fontSize='small' />
      </Box>
    </Box>
  );
}

interface SilenceDetectionProps {
  volumeLevel: number;
  threshold: number;
}

function SilenceDetection({ volumeLevel, threshold }: SilenceDetectionProps) {
  const [showWarning, setShowWarning] = useState(false);
  const silenceDurationRef = useRef<number>(0);

  useEffect(() => {
    if (volumeLevel < threshold) {
      silenceDurationRef.current += 20;
      if (silenceDurationRef.current >= intervalForSilenceDetection) {
        silenceDurationRef.current = 0;
        trackMixpanelEvent(MixpanelEventName.SESSION_SILENCE_DETECTED);
        setShowWarning(true);
      }
    }
    else {
      silenceDurationRef.current = 0;
      setShowWarning(false);
    }
  }, [volumeLevel, threshold]);

  const handleClose = () => {
    setShowWarning(false);
  };

  return (
    <>
      <Snackbar
        open={showWarning}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert severity='warning' sx={{ width: '100%' }} onClose={handleClose}>
          We have detected silence. Are you still recording?
        </Alert>
      </Snackbar>
      <RecordingIndicator volumeLevel={volumeLevel} />
    </>
  );
}

export default SilenceDetection;
