import React from 'react';
import { useQuery } from 'react-query';
import { sessionRecordingUrls } from 'utils/apiUrls';
import { useHttp } from 'hooks/use-fetch';
import { fetchMetadata } from 'pages/Dashboard/services/sessionrecording.services';
import { useParams } from 'react-router-dom';
import { Box, Button, Card, Drawer, Skeleton, Typography } from '@mui/material';
import { SentimentBox } from 'pages/Dashboard/components/SessionRecording/AINotesTab';
import Theme from 'theme';
import { ArrowForwardOutlined, RadioButtonChecked } from '@mui/icons-material';
import Results from 'pages/Dashboard/components/SessionRecording/Results';
import { GetMetadataResponse, Session, Status } from 'pages/Dashboard/types';
import { formatTime } from 'utils/dateUtils';
import { ReactComponent as NoSessionsIcon } from 'assets/NoSession.svg';
import { ToastProps } from 'components/Common/Toast';
import { PatientCtx } from 'pages/Dashboard/constants';
import InfiniteScroll from 'components/Common/InfiniteScroll';
import useConfirm from 'hooks/use-confirm';

type SessionCardProps = {
  name: string;
  sessionId: string;
  sentimentAnalysis: Record<string, string>;
  sessionDuration: string;
  onClickHandler: ({ id, name }: {id: string, name: string }) => void;
};

function SessionCard({
  name,
  sessionId,
  sentimentAnalysis,
  sessionDuration,
  onClickHandler,
}: SessionCardProps) {
  const sentiments = Object.keys(sentimentAnalysis ?? {}).map((key) => ({
    text: key,
    percentage: sentimentAnalysis[key],
  }));

  const formattedTime = formatTime(+sessionDuration, true);

  return (
    <Card
      sx={{ p: 2, mb: 1.5, borderRadius: 1, cursor: 'pointer' }}
      onClick={() => onClickHandler({ id: sessionId, name })}
    >
      <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
        <Box display='flex' flexDirection='column' maxWidth='80%'>
          <Typography variant='h5' color={Theme.custom.colors.lightTextPrimary} fontWeight={400}>
            {name || 'Session Name'}
          </Typography>
          <Typography variant='body2' color={Theme.custom.colors.lightTextSecondary}>
            Session time:
            {' '}
            {formattedTime.time}
          </Typography>
        </Box>
        <ArrowForwardOutlined sx={{ color: Theme.custom.colors.primaryMain }} />
      </Box>
      <Box display='flex' flexDirection='row' gap={1.5} mt={3}>
        {sentiments.map((item) => (
          <SentimentBox key={item.text} text={item.text} percentage={item.percentage} />
        ))}
      </Box>
    </Card>
  );
}

interface ISessionProps {
  sessions: Session[];
  isFetching: boolean;
  total: number;
  fetchSessions: (params: { offset: number }) => void;
  setToastProps: React.Dispatch<React.SetStateAction<ToastProps | null>>;
}

function Sessions({ sessions, isFetching, total, fetchSessions, setToastProps }: ISessionProps) {
  const { id } = useParams();
  const patientId = id ?? '';
  const { providerId, http } = useHttp();
  const { cb, recordingStatus, isEditingNotes } = React.useContext(PatientCtx);

  const { ConfirmDialog, showConfirm } = useConfirm();

  const [offset, setOffset] = React.useState<number>(0);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [selectedSession, setSelectedSession] = React.useState({} as { id: string, name: string });

  const { data: metadata, isLoading: isLoadingMetadata } = useQuery(
    [sessionRecordingUrls.getMetadata.queryUrl],
    () => fetchMetadata(http.get, providerId, patientId ?? ''),
  );

  const handleSessionClick = (session: { id: string, name: string }) => {
    setIsDrawerOpen(true);
    setSelectedSession(session);
  };

  const loadMore = () => {
    const updatedOffset = offset + 10;
    setOffset(updatedOffset);
    fetchSessions({ offset: updatedOffset });
  };

  React.useEffect(() => {
    fetchSessions({ offset: 0 });
  }, [fetchSessions]);

  const onClose = () => {
    if (isEditingNotes) {
      showConfirm({
        message: 'Are you sure you want to discard the changes?',
        onConfirm: () => {
          setIsDrawerOpen(false);
        },
      });
    }
    else {
      setIsDrawerOpen(false);
    }
  };

  return (
    <Box mt={3}>
      <ConfirmDialog />
      <Drawer
        anchor='right'
        open={isDrawerOpen}
        onClose={onClose}
        PaperProps={{ sx: { maxWidth: 'lg', width: '100%', overflow: 'hidden' } }}
      >
        <Results
          generatedNotes={{
            sessionId: selectedSession.id,
            sessionName: selectedSession.name,
          } as Session}
          isDrawerOpen={isDrawerOpen}
          metadata={metadata as GetMetadataResponse}
          isLoadingMetadata={isLoadingMetadata}
          setIsDrawerOpen={setIsDrawerOpen}
          setToastProps={setToastProps}
        />
      </Drawer>
      <Box maxWidth='md'>
        <InfiniteScroll
          items={sessions}
          hasMore={total > sessions.length}
          isLoading={isFetching}
          loadMore={loadMore}
          getItemKey={(session: Session) => session.sessionId}
          renderLoader={() => (
            <>
              {[...Array(10)].map(() => (
                <Skeleton variant='rectangular' height={150} sx={{ mb: 1.5, borderRadius: 1, maxWidth: 'md' }} data-testid='skeleton' />
              ))}
            </>
          )}
          renderItem={(session: Session) => (
            <SessionCard
              key={session.sessionId}
              name={session.sessionName}
              sessionId={session.sessionId}
              sessionDuration={session.sessionDuration}
              sentimentAnalysis={session.sentimentAnalysis}
              onClickHandler={handleSessionClick}
            />
          )}
        />
      </Box>
      {!isFetching && sessions.length === 0 && (
      <Box height={550} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
        <NoSessionsIcon />
        <Typography variant='h6' mt={5} mb={2}>No recordings yet</Typography>
        <Typography variant='body1' mb={8}>Session recordings with this patient will appear here</Typography>
        {recordingStatus === Status.DEFAULT && (
        <Button
          variant='contained'
          color='primary'
          startIcon={<RadioButtonChecked />}
          onClick={cb}
        >
          Record Session
        </Button>
        )}
      </Box>
      )}
    </Box>
  );
}

export default Sessions;
