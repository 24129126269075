import { formatISO } from 'date-fns';

export interface IFetchParams {
  method?: string;
  [k: string]: string | number | undefined | Partial<RequestInit> | Partial<HeadersInit>;
}

export enum ResponseType {
  JSON = 'JSON',
  BLOB = 'BLOB',
}

// eslint-disable-next-line no-underscore-dangle
export const _fetch = async (
  url: string,
  { method, headers, ...rest }: IFetchParams,
) => {
  const currentTime = formatISO(new Date());
  const fetchHeaders = new Headers();
  fetchHeaders.set('Accept', 'application/json');
  fetchHeaders.set('Access-Control-Allow-Credentials', 'true');
  fetchHeaders.set('Access-Control-Allow-Origin', 'true');
  fetchHeaders.set('Content-Type', 'application/json');
  const requestHeaders = { ...fetchHeaders, ...(headers as Headers) };

  const executeFetch = async () => {
    try {
      const response = await fetch(url, {
        method,
        headers: { ...requestHeaders, 'current-time': currentTime },
        credentials: 'include',
        ...rest,
      });

      if (response.status === 201 || response.status === 204) {
        return Promise.resolve({ success: true });
      }

      if (response.status >= 400) {
        const { error, message } = await response.json();
        return Promise.reject(
          new Error(
            error.message || message || 'Your request could not be completed, please try again.',
          ),
        );
      }

      return ['application/octet-stream', 'application/pdf'].includes(
        (headers as never)?.['Content-Type'],
      )
        ? await response.blob()
        : await response.json();
    }
    catch (err) {
      /* Catch network/non-API errors */
      return Promise.reject(new Error('Could not establish link with server'));
    }
  };

  if (!navigator.onLine) {
    if (rest.retryOnOffline === 'true') {
      return new Promise((resolve, reject) => {
        const handleOnline = async () => {
          window.removeEventListener('online', handleOnline);
          try {
            const result = await executeFetch();
            resolve(result);
          }
          catch (error) {
            reject(error);
          }
        };
        window.addEventListener('online', handleOnline);
      });
    }
  }

  return executeFetch();
};

export async function retryFetch(url: string, options = {}, retries = 3): Promise<Response> {
  for (let i = 0; i < retries; i += 1) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response; // Success, return the response
    }
    catch (error) {
      if (i === retries - 1) {
        throw error; // Re-throw the last error if all retries fail
      }
    }
  }
  throw new Error('Failed to fetch after multiple retries');
}

const http = {
  delete: async (url: string) => _fetch(url, { method: 'DELETE' }),

  get: async (url: string, params?: IFetchParams) => _fetch(url, { method: 'GET', ...(params ?? {}) }),

  patch: async (url: string, payload: object) => _fetch(url, { method: 'PATCH', body: JSON.stringify(payload) }),

  post: async (url: string, payload: object, params?: IFetchParams) => _fetch(url, { method: 'POST', body: JSON.stringify(payload), ...(params ?? {}) }),

  put: async (url: string, payload: object) => _fetch(url, { method: 'PUT', body: JSON.stringify(payload) }),
} as const;

export type HttpType = typeof http;

type HttpKeys = keyof HttpType;

export type HttpMethods = (typeof http)[HttpKeys];

export default http;
