import { Box, Typography } from '@mui/material';
import React from 'react';
import Theme from 'theme';

function TranscriptTab({ transcript }: { transcript: string }) {
  return (
    <Box py={3}>
      <Typography variant='body1' color={Theme.custom.colors.lightTextPrimary}>{transcript}</Typography>
    </Box>
  );
}

export default TranscriptTab;
