import React from 'react';
import { Box, Button, Drawer, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import Theme from 'theme';
import RefreshIcon from '@mui/icons-material/Refresh';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AppointmentCalendar from 'pages/Dashboard/AppointmentCalendar';
import { UseMutateFunction } from 'react-query';
import { HrRefreshCreatePayload } from 'pages/Dashboard/types/healthRecords.types';
import { trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';
import { useParams } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { formatTimeDifference, GoogleDate } from 'utils/dateUtils';

interface RecordRefreshDrawerProps {
    isOpen: boolean;
    toggleDrawer: (open: boolean) => void;
    hrRefreshCreation: UseMutateFunction<void, unknown, HrRefreshCreatePayload, unknown>;
    setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>;
    selectedDate: Date | null;
    getHrRefreshHistoryLastDate: GoogleDate | undefined;
    setSelectedChip: React.Dispatch<React.SetStateAction<boolean>>;
}

function RecordRefreshDrawer({ isOpen, toggleDrawer, hrRefreshCreation,
  setSelectedDate, selectedDate, getHrRefreshHistoryLastDate, setSelectedChip }:
   RecordRefreshDrawerProps) {
  const { id } = useParams();
  const handleDrawerClose = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    toggleDrawer(false);
    setSelectedDate(null);
  };

  const createRecordRefresh = () => {
    if (selectedDate) {
      const googleDate = {
        date: {
          year: selectedDate.getFullYear(),
          month: selectedDate.getMonth() + 1,
          day: selectedDate.getDate(),
        },
      };
      trackMixpanelEvent(MixpanelEventName.HEALTH_RECORDS_REFRESH_REQUESTED, {
        'patient id': id ?? '',
        'appointment date': `${googleDate.date.day}-${googleDate.date.month}-${googleDate.date.year}`,
        'days since last fetch': `${getHrRefreshHistoryLastDate?.day && getHrRefreshHistoryLastDate?.month && getHrRefreshHistoryLastDate?.year
          ? formatTimeDifference(getHrRefreshHistoryLastDate as GoogleDate) : 'Unknown'}`,
      });
      setSelectedChip(false);
      hrRefreshCreation({ appointment: googleDate });
    }
  };

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={handleDrawerClose}
      sx={{
        height: '100%',
        width: '35%',
        '& .MuiDrawer-paper': { width: '35%', boxSizing: 'border-box' },
      }}
    >
      <Box display='flex' flexDirection='column' flex={1} overflow='hidden'>
        <Box
          display='flex'
          flexDirection='row'
          width='100%'
          p={2}
          pb={3}
          mt={1}
          mb={1}
          alignItems='center'
          justifyContent='space-between'
          height={10}
          sx={{ borderBottom: `1px solid ${Theme.custom.colors.backgroundColorSecondary}` }}
        >
          <Typography fontSize={20} fontWeight={500} color={Theme.custom.colors.lightTextPrimary} variant='h6'>
            Medical history records refresh
          </Typography>
          <Close sx={{ color: '#0000008A' }} onClick={handleDrawerClose} />
        </Box>
        <Box pt={3} pl={2.5}>
          <Typography fontSize={20} fontWeight={500} color={Theme.custom.colors.lightTextPrimary} variant='h6'>
            When is the patient’s next appointment?
          </Typography>
          <Typography fontSize={14} fontWeight={400} color={Theme.custom.colors.lightTextPrimary} variant='body2' pt={3}>
            To request a medical record refresh, the patient’s next appointment is required.
          </Typography>
          <Box
            display='flex'
            flexDirection='row'
            p={2}
            mt={3}
            mb={1}
            mr={3}
            alignItems='center'
            height={48}
            borderRadius={1}
            sx={{ backgroundColor: '#E5F3FA' }}
          >
            <InfoOutlinedIcon sx={{ color: Theme.custom.colors.primaryMain }} />
            <Typography fontSize={14} fontWeight={400} color={Theme.custom.colors.lightTextPrimary} variant='body2' ml={1.5}>
              Health records can be refreshed every
              {' '}
              <strong>60 days.</strong>
            </Typography>
          </Box>
          <AppointmentCalendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
          <Button
            onClick={() => createRecordRefresh()}
            color='success'
            variant='contained'
            size='large'
            style={{ marginTop: 48, boxShadow: !selectedDate ? 'none' : '0px 1px 5px 0px #0000001F, 0px 2px 2px 0px #00000024, 0px 3px 1px -2px #00000033',
            }}
            disabled={!selectedDate}
            startIcon={(
              <RefreshIcon
                fontSize='large'
              />
        )}
          >
            REFRESH RECORDS
          </Button>
        </Box>
        {selectedDate && (
        <Box pt={2} pl={2.5} display='flex' flexDirection='row'>
          <NotificationsIcon sx={{ color: '#0000008A', marginTop: 0.15 }} />
          <Typography fontSize={16} fontWeight={400} color={Theme.custom.colors.lightTextSecondary} variant='body1' pl={1}>
            This may take up to 36 hours.
            <br />
            A notification will be sent when the refresh completes.
            {' '}
          </Typography>
        </Box>
        )}
      </Box>
    </Drawer>
  );
}

export default RecordRefreshDrawer;
