import React from 'react';
import {
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  Chip,
} from '@mui/material';
import Theme from 'theme';
import { calculateDaysDifference, formatTimeDifference } from 'utils/dateUtils';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import RefreshIcon from '@mui/icons-material/Refresh';
import { UseQueryResult } from 'react-query';
import { HrRefreshHistory, HrRefreshHistoryStatus } from 'pages/Dashboard/types/healthRecords.types';
import { trackMixpanelEvent } from 'utils/utilMethods';
import { DaysDifferenceHrRefresh, MixpanelEventName } from 'utils/constants';
import { useHttp } from 'hooks/use-fetch';

interface HealthRecordRefreshProps {
    isRecordStatusRetreived: boolean;
    isLoading: boolean;
    handleRecordRefreshDrawer: () => void;
    handleMedicationHistoryDrawer: () => void;
    getHrRefreshHistory: UseQueryResult<HrRefreshHistory, unknown>;
    refreshStatus: HrRefreshHistoryStatus | undefined;
    hrCount: number;
    hrCountFromHistory: number;
    selectedChip: boolean;
    setSelectedChip: (value: boolean) => void;
    handleChipClick: () => void;
}

function HealthRecordRefresh({
  isRecordStatusRetreived,
  isLoading,
  handleRecordRefreshDrawer,
  getHrRefreshHistory,
  refreshStatus, hrCount,
  hrCountFromHistory,
  selectedChip,
  setSelectedChip,
  handleChipClick,
  handleMedicationHistoryDrawer,
}: HealthRecordRefreshProps) {
  const { isHrRefreshEnabled } = useHttp();
  let refreshContent = null;

  const [refreshing, setRefreshing] = React.useState(false);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setRefreshing(true);
      setTimeout(() => setRefreshing(false), 2000);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  if (refreshStatus === HrRefreshHistoryStatus.IN_PROGRESS) {
    refreshContent = (
      <>
        <RefreshIcon
          sx={{
            color: '#0000008A',
            marginLeft: 1,
            marginRight: 0.5,
            animation: refreshing ? 'spin 2s infinite linear' : 'none',
            '@keyframes spin': {
              '0%': {
                transform: 'rotate(0deg)',
              },
              '100%': {
                transform: 'rotate(360deg)',
              },
            },
          }}
        />
        <Typography
          fontSize={16}
          fontWeight={400}
          color={Theme.custom.colors.primaryMain}
          variant='body1'
          onClick={handleMedicationHistoryDrawer}
        >
          Refresh in progress
        </Typography>
      </>
    );
  }
  else if (refreshStatus === HrRefreshHistoryStatus.COMPLETED) {
    refreshContent = (
      <Typography
        fontSize={16}
        fontWeight={400}
        color={Theme.custom.colors.primaryMain}
        variant='body1'
        onClick={handleMedicationHistoryDrawer}
      >
        Refreshed
        {' '}
        {getHrRefreshHistory.data?.refreshHistory[0]?.scheduledOn
          ? formatTimeDifference(getHrRefreshHistory.data?.refreshHistory[0]?.scheduledOn)
          : null}
      </Typography>
    );
  }
  else if (refreshStatus === HrRefreshHistoryStatus.INTIAL_REQUEST) {
    refreshContent = (
      <Typography
        fontSize={16}
        fontWeight={400}
        color={Theme.custom.colors.primaryMain}
        variant='body1'
        onClick={handleMedicationHistoryDrawer}
      >
        Records retrieved
        {' '}
        {getHrRefreshHistory.data?.refreshHistory[0]?.scheduledOn
          ? formatTimeDifference(getHrRefreshHistory.data?.refreshHistory[0]?.scheduledOn)
          : null}
      </Typography>
    );
  }

  const isRefreshDisabled = () => {
    const refreshHistory = getHrRefreshHistory.data?.refreshHistory;
    if (refreshHistory && refreshHistory.length >= 0) {
      const firstScheduledDate = refreshHistory[0]?.scheduledOn;
      const daysDifference = calculateDaysDifference(firstScheduledDate, new Date());
      return daysDifference <= DaysDifferenceHrRefresh;
    }
    return false;
  };

  const deSelectChip = () => {
    setSelectedChip(false);
    trackMixpanelEvent(MixpanelEventName.SHOW_RECORDS_FROM_LAST_REFRESH_BUTTON_DESELECTED, {
      'fetch in progress yes/no': refreshStatus === HrRefreshHistoryStatus.IN_PROGRESS ? 'yes' : 'no',
    });
  };

  const isDisabled = isRefreshDisabled();

  const showChipText = (hrCount > 0 || hrCountFromHistory > 0) && (getHrRefreshHistory
    .data?.refreshHistory?.length ?? 0) > 1;

  const shouldEnableHrRefresh = (
    (!isRecordStatusRetreived
        || (getHrRefreshHistory?.data?.refreshHistory?.length
         && getHrRefreshHistory?.data?.refreshHistory?.length > 1))
      && isHrRefreshEnabled
  );

  const hrRecordLabel = (hrCount > 1 || hrCountFromHistory > 1) ? 'records' : 'record';

  return (
    <>
      {isRecordStatusRetreived && !isLoading
      && (getHrRefreshHistory.data?.refreshHistory
        && getHrRefreshHistory.data.refreshHistory.length <= 1) && (
        <Card sx={{ backgroundColor: '#E9F9EA',
          borderRadius: 3,
          marginBottom: 3,
          border: '1px solid',
          boxShadow: 'none',
          width: '50%',
          borderColor: Theme.custom
            .colors.lightSuccessMain }}
        >
          <CardContent>
            <Box display='flex' alignItems='center' gap={1}>
              <AutorenewIcon
                color='success'
                sx={{
                  animation: refreshing ? 'spin 2s infinite linear' : 'none',
                  '@keyframes spin': {
                    '0%': {
                      transform: 'rotate(0deg)',
                    },
                    '100%': {
                      transform: 'rotate(360deg)',
                    },
                  },
                }}
              />
              <Typography variant='h6'>
                In progress
              </Typography>
            </Box>
            <Typography variant='body1' marginTop={1.5}>
              Records are still being retrieved. Any information shown may not be complete.
            </Typography>
          </CardContent>
        </Card>
      )}
      {shouldEnableHrRefresh && (
      <Box
        display='flex'
        justifyContent='flex-start'
        alignItems='center'
        marginBottom={3}
      >
        <Button
          onClick={() => handleRecordRefreshDrawer()}
          variant='contained'
          size='large'
          style={{ boxShadow: 'none',
            marginRight: 16,
            backgroundColor: !isDisabled ? Theme.custom.colors.primaryMain : undefined,
            color: !isDisabled ? Theme.custom.colors.white : undefined }}
          disabled={isDisabled}
          startIcon={
            <RefreshIcon fontSize='large' />
        }
        >
          REFRESH RECORDS
        </Button>
        <Box display='flex' alignItems='center'>
          {refreshContent}
          {getHrRefreshHistory.data?.refreshHistory[0]?.hrCount === 0
                && getHrRefreshHistory.data?.refreshHistory[0]?.status
                === HrRefreshHistoryStatus.COMPLETED && (
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  marginLeft={1}
                  color={Theme.custom.colors.lightTextPrimary}
                  variant='body1'
                >
                  - 0 new records found
                </Typography>
          )}
          {showChipText && (
            <Chip
              label={`Show ${(hrCount || hrCountFromHistory) === 1 ? 'record' : 'records'}
               from latest refresh (${hrCount || hrCountFromHistory})`}
              onClick={handleChipClick}
              onDelete={selectedChip ? deSelectChip : undefined}
              color='primary'
              clickable
              sx={{
                backgroundColor: selectedChip ? '#22516B' : '#E0E0E0',
                color: selectedChip ? '#FFFFFF' : '#000000',
                marginLeft: 2,
                '&:hover': {
                  color: '#FFFFFF',
                },
              }}
            />
          )}
        </Box>
      </Box>
      )}
      {showChipText && selectedChip && isHrRefreshEnabled && (
        <Typography variant='h6' fontWeight={500} fontSize={20} color={Theme.custom.colors.lightTextSecondary} mb={3}>
          Showing results
          {' '}
          <span style={{ color: Theme.custom.colors.lightTextPrimary }}>
            (
            {hrCount || hrCountFromHistory}
            {' '}
            {hrRecordLabel}
            )
          </span>
          {' '}
          from latest data refresh
        </Typography>
      )}
    </>
  );
}

export default HealthRecordRefresh;
