import mixpanel, { Config, Mixpanel } from 'mixpanel-browser';

const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN || '';
const mixpanelTokenSessionReplay = process.env.REACT_APP_MIXPANEL_TOKEN_FOR_SESSION_REPLAY || '';
const mixpanelEventInstance:Mixpanel = mixpanel.init(mixpanelToken, { debug: true,
  track_pageview: true,
  persistence: 'localStorage' } as Partial<Config>, 'instance1');
const mixpanelSessionReplay = mixpanel.init(mixpanelTokenSessionReplay, {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
  record_sessions_percent: 0,
  record_mask_text_class: 'fs-mask',
  record_mask_text_selector: 'fs-mask' } as Partial<Config>, 'mixpanelSessionReplay');

export { mixpanelEventInstance,
  mixpanelSessionReplay,
};
