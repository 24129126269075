import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  Check,
  ContentCopy,
  Download,
  Edit,
  ErrorOutline,
  Save,
  ThumbDownAlt,
  ThumbDownOutlined,
  ThumbUpAlt,
  ThumbUpOutlined,
} from '@mui/icons-material';
import LoadingButton from 'components/Common/LoadingButton';
import { ToastProps, ToastType } from 'components/Common/Toast';
import ConfirmPopup from 'pages/AdminPortal/Actions/ConfirmPopup';
import TextEditor, { HtmlPreview } from 'pages/Dashboard/components/SessionRecording/TextEditor';
import { editTranscript, fetchPDFUrl, sendFeedbackForTranscript } from 'pages/Dashboard/services/sessionrecording.services';
import { Session } from 'pages/Dashboard/types';
import { useHttp } from 'hooks/use-fetch';
import Theme from 'theme';
import { MixpanelEventName } from 'utils/constants';
import { logSentryError, trackMixpanelEvent } from 'utils/utilMethods';
import { PatientCtx } from 'pages/Dashboard/constants';
import { IconButton, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

// Styled Tooltip with custom look
const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    arrow
    placement='bottom-start'
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.custom.tooltipBG,
    color: theme.custom.colors.lightTextPrimary,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    maxWidth: 220,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.custom.tooltipBG,
  },
}));

function TooltipContent() {
  return (
    <Box display='flex' alignItems='flex-start' gap={1}>
      <Check sx={{ color: Theme.custom.colors.lightSuccessMain }} fontSize='large' />
      <Box>
        <Typography variant='body1' fontWeight='bold'>
          Copied
        </Typography>
        <Typography variant='body2' sx={{ mt: 1 }} fontSize={16}>
          Paste in your EHR
        </Typography>
      </Box>
    </Box>
  );
}

interface IconBoxProps {
  Icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>> & {
    muiName: string;
  };
  testId: string;
  isLoading?: boolean;
  onClickHandler: () => void;
}

function IconBox({ Icon, testId, isLoading, onClickHandler } : IconBoxProps) {
  const [showTooltip, setShowTooltip] = React.useState(false);

  const onClick = async () => {
    await onClickHandler();
    if (testId === 'copy-icon') {
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 5000);
    }
  };

  return (
    <StyledTooltip title={<TooltipContent />} open={showTooltip}>
      <IconButton
        onClick={onClick}
        sx={{
          width: 48,
          height: 42,
          pointerEvents: isLoading ? 'none' : 'auto',
          opacity: isLoading ? 0.5 : 1,
          border: '1px solid',
          borderColor: Theme.custom.colors.primaryMain,
          borderRadius: 1,
        }}
        data-testid={testId}
      >
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <Icon sx={{ color: Theme.custom.colors.primaryMain }} />
        )}
      </IconButton>
    </StyledTooltip>
  );
}

const colorMap: Record<string, string> = {
  positive: Theme.custom.colors.lightSuccessMain,
  neutral: Theme.custom.colors.primaryMain,
  negative: Theme.custom.colors.lightErrorMain,
};

const transparencyMap: Record<string, string> = {
  positive: '1F',
  neutral: '14',
  negative: '0D',
};

interface SentimentBoxProps {
  text: string;
  percentage: string;
}

export function SentimentBox({ text, percentage } : SentimentBoxProps) {
  const color = colorMap[text.toLowerCase()];
  const transparencyCode = transparencyMap[text.toLowerCase()];

  return (
    <Typography
      sx={{ background: `${color}${transparencyCode}` }}
      px={1.5}
      py={1}
      borderRadius={6}
      color={color}
    >
      {text}
      :
      {' '}
      {percentage}
    </Typography>
  );
}

interface AINotesTabProps {
  feedbackItems: { key: string, displayName: string }[];
  templateItems: { id: string, display: string }[];
  session: Session;
  setSession: (session: Session) => void;
  setToastProps: React.Dispatch<React.SetStateAction<ToastProps | null>>;
}

function AINotesTab({
  feedbackItems,
  templateItems,
  session,
  setSession,
  setToastProps,
}: AINotesTabProps) {
  const { id } = useParams();
  const patientId = id ?? '';
  const { providerId, http } = useHttp();
  const { setIsEditingNotes } = useContext(PatientCtx);

  const [isEditing, setIsEditing] = React.useState(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = React.useState(false);
  const [feedback, setFeedback] = React.useState(
    {} as { liked: boolean; reasons: Record<string, string> },
  );
  const [editorValue, setEditorValue] = React.useState('');
  const [plainText, setPlainText] = React.useState('');

  const dislikeOptionsContainer = React.useRef<HTMLDivElement | null>(null);

  const { mutateAsync: getPDFUrl, isLoading: isLoadingPDFUrl } = useMutation(() => fetchPDFUrl(
    http.get,
    providerId,
    patientId,
    session?.sessionId ?? '',
  ), {
    onSuccess: (data: { url: string }) => {
      window.open(data.url, '_blank');
    },
    onError: (err) => {
      setToastProps({ message: 'Failed to download notes.', open: true, type: ToastType.error });
      logSentryError(`Failed to download notes - ${err instanceof Error ? err.message : JSON.stringify(err)}`);
    },
  });

  const { mutate: submitFeedback, isLoading } = useMutation((
    { liked }: { liked: boolean },
  ) => sendFeedbackForTranscript(
    http.post,
    providerId,
    patientId,
    session?.sessionId ?? '',
    {
      upvote: liked ? 'UP_VOTE' : 'DOWN_VOTE',
      feedbackItems: liked ? [] : Object.keys(feedback.reasons),
      feedback: '',
    },
  ), {
    onSuccess: () => {
      setToastProps({ message: 'Your feedback has been successfully submitted.', open: true, type: ToastType.success });
    },
    onError: (err) => {
      setToastProps({ message: 'Failed to submit feedback.', open: true, type: ToastType.error });
      logSentryError(`Failed to submit feedback - ${err instanceof Error ? err.message : JSON.stringify(err)}`);
    },
  });

  const { mutate: updateTranscript, isLoading: isSavingNotes } = useMutation(
    () => editTranscript(http.post, providerId, patientId, session?.sessionId ?? '', {
      sessionNotes: editorValue,
    }),
    {
      onSuccess: (data: { sessions: Session[] }) => {
        setSession(data?.sessions?.[0]);
      },
    },
  );

  const sentimentAnalysis = Object.keys(session?.sentimentAnalysis ?? {}).map((key) => ({
    text: key,
    percentage: session.sentimentAnalysis[key],
  }));

  useEffect(() => {
    if (session?.feedback?.upvote) {
      const isUpVote = session.feedback.upvote === 'UP_VOTE';
      setFeedback({
        liked: isUpVote,
        reasons: isUpVote
          ? {}
          : (session?.feedback?.feedbackBucket ?? []).reduce(
            (acc, item) => ({ ...acc, [item]: true }),
            {},
          ),
      });
    }
  }, [session]);

  const onTemplateChange = (event: SelectChangeEvent<string>) => {
    const templateId = event.target.value as string;
    const template = templateItems.find((item) => item.id === templateId);
    if (template) {
      trackMixpanelEvent(MixpanelEventName.SESSION_TEMPLATE_SELECTED, {
        session: session.sessionId,
        template: template.display,
      });
    }
  };

  const onDownload = async () => {
    trackMixpanelEvent(MixpanelEventName.DOWNLOAD_NOTES_BUTTON_CLICKED, {
      session: session.sessionId,
    });
    getPDFUrl();
  };

  const onCopy = async () => {
    trackMixpanelEvent(MixpanelEventName.COPY_NOTES_BUTTON_CLICKED, {
      session: session.sessionId,
    });
    await navigator.clipboard.writeText(plainText);
  };

  const onEdit = async () => {
    trackMixpanelEvent(MixpanelEventName.EDIT_NOTES_BUTTON_CLICKED, {
      session: session.sessionId,
    });
    setIsEditingNotes(true);
    setIsEditing(true);
    setEditorValue(session.notes);
  };

  const onCancel = (trackEvent = true) => {
    if (trackEvent) {
      trackMixpanelEvent(MixpanelEventName.EDIT_NOTES_CANCEL_BUTTON_CLICKED, {
        session: session.sessionId,
      });
    }

    setIsEditingNotes(false);
    setIsEditing(false);
  };

  const onConfirm = () => {
    setIsConfirmPopupOpen(false);
    onCancel(true);
  };

  const onSaveChanges = () => {
    trackMixpanelEvent(MixpanelEventName.EDIT_NOTES_SAVE_BUTTON_CLICKED, {
      session: session.sessionId,
    });
    updateTranscript();
    onCancel(false);
  };

  const onCancelHandler = () => {
    if (editorValue !== session.notes) {
      setIsConfirmPopupOpen(true);
    }
    else {
      onCancel(true);
    }
  };

  const onLike = () => {
    trackMixpanelEvent(MixpanelEventName.SESSION_LIKE_BUTTON_CLICKED, {
      session: session.sessionId,
    });
    setFeedback({ liked: true, reasons: {} });
    submitFeedback({ liked: true });
  };

  const onDislike = () => {
    trackMixpanelEvent(MixpanelEventName.SESSION_DISLIKE_BUTTON_CLICKED, {
      session: session.sessionId,
    });
    setFeedback({ liked: false, reasons: {} });
    setTimeout(() => {
      if (dislikeOptionsContainer.current) {
        dislikeOptionsContainer.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 50);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    if (event.target.checked) {
      setFeedback((prev) => ({
        ...prev,
        reasons: { ...(prev.reasons ?? {}), [key]: key },
      }));
    }
    else {
      setFeedback((prev) => {
        const reasons = { ...prev.reasons };
        delete reasons[key];
        return {
          ...prev,
          reasons,
        };
      });
    }
  };

  const onSubmit = () => {
    trackMixpanelEvent(MixpanelEventName.SESSION_DISLIKED_OPTIONS_SUBMITTED, {
      session: session.sessionId,
      options: Object.keys(feedback.reasons).join(', '),
    });
    submitFeedback({ liked: false });
  };

  const isLiked = feedback.liked === true;
  const isDisliked = feedback.liked === false;

  const LikedComponent = isLiked ? ThumbUpAlt : ThumbUpOutlined;
  const DislikedComponent = isDisliked ? ThumbDownAlt : ThumbDownOutlined;

  return (
    <Box pt={3} display='flex' flexDirection='column' overflow='auto'>
      <ConfirmPopup
        open={isConfirmPopupOpen}
        onClose={() => setIsConfirmPopupOpen(false)}
        title='Are you sure you want to discard changes?'
        message='You have unsaved changes. Are you sure you want to discard them?'
        onConfirm={onConfirm}
      />
      {isEditing ? (
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='flex-end'
          alignItems='center'
          gap={2}
          height={42}
        >
          <Button variant='outlined' onClick={onCancelHandler}>
            CANCEL
          </Button>
          <LoadingButton
            variant='contained'
            startIcon={<Save />}
            onClick={onSaveChanges}
            label='SAVE CHANGES'
            isLoading={isSavingNotes}
          />
        </Box>
      ) : (
        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
          <FormControl size='small'>
            <InputLabel id='template-select-label'>Template</InputLabel>
            <Select
              labelId='template-select-label'
              value={templateItems?.[0]?.id}
              label='Template'
              onChange={onTemplateChange}
            >
              {templateItems.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.display}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box display='flex' flexDirection='row' gap={1.5}>
            <IconBox Icon={Download} onClickHandler={onDownload} testId='download-icon' isLoading={isLoadingPDFUrl} />
            <IconBox Icon={ContentCopy} onClickHandler={onCopy} testId='copy-icon' />
            <IconBox Icon={Edit} onClickHandler={onEdit} testId='edit-icon' />
          </Box>
        </Box>
      )}
      <Divider sx={{ my: 3 }} />
      <Box>
        <Typography variant='h6' color={Theme.custom.colors.lightTextSecondary} textAlign='center'>
          Generated note
        </Typography>
        <Box
          display='flex'
          flexDirection='row'
          gap={1}
          justifyContent='center'
          alignItems='center'
          mb={3}
          mt={1}
        >
          <ErrorOutline sx={{ color: Theme.custom.colors.lightWarningMain }} fontSize='medium' />
          <Typography variant='body2' color={Theme.custom.colors.lightTextSecondary}>
            AI can be incorrect, ensure you review the note
          </Typography>
        </Box>
        {!isEditing && sentimentAnalysis.length > 0 && (
          <Box>
            <Typography variant='h6' color={Theme.custom.colors.lightTextPrimary}>
              Sentiment Analysis
            </Typography>
            <Box display='flex' flexDirection='row' gap={1.5} my={1.5}>
              {sentimentAnalysis.map((item) => (
                <SentimentBox text={item.text} percentage={item.percentage} />
              ))}
            </Box>
          </Box>
        )}
      </Box>
      <Box mb={2} mt={3}>
        {isEditing ? (
          <TextEditor value={editorValue} setValue={setEditorValue} />
        ) : (
          <HtmlPreview htmlContent={session.notes} onPlainTextSave={setPlainText} />
        )}
      </Box>
      {!isEditing && (
        <Box>
          <Box display='flex' flexDirection='row' gap={3} mt={1.5}>
            <LikedComponent
              sx={{
                color: isLiked ? Theme.custom.colors.primaryMain : '#0000008A',
                cursor: 'pointer',
                pointerEvents: isLoading ? 'none' : 'auto',
              }}
              fontSize='small'
              onClick={onLike}
              data-testid='like-button'
            />
            <DislikedComponent
              sx={{
                color: isDisliked ? Theme.custom.colors.primaryMain : '#0000008A',
                cursor: 'pointer',
                pointerEvents: isLoading ? 'none' : 'auto',
              }}
              fontSize='small'
              onClick={onDislike}
              data-testid='dislike-button'
            />
          </Box>
          {isDisliked && (
            <Grid container mt={2} width='75%' ref={dislikeOptionsContainer}>
              {feedbackItems.map((item) => (
                <Grid item xs={6} key={item.key}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={!!feedback.reasons[item.key]}
                        onChange={(e) => handleChange(e, item.key)}
                        disabled={isLoading}
                      />
                    )}
                    label={item.displayName}
                  />
                </Grid>
              ))}
              <Grid item xs={12} mt={2}>
                <LoadingButton variant='contained' size='small' onClick={onSubmit} label='Submit' isLoading={isLoading} />
              </Grid>
            </Grid>
          )}
        </Box>
      )}
      <Divider sx={{ my: 3 }} />
    </Box>
  );
}

export default AINotesTab;
