import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import UserSettings from 'components/Layouts/UserSettings';
import { LogoUrl, MixpanelEventName } from 'utils/constants';
import { FetchContext, useHttp } from 'hooks/use-fetch';
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router-dom';
import { ProviderPractice } from 'pages/SuperAdmin/types';
import Tooltip from '@mui/material/Tooltip';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, IconButton, keyframes } from '@mui/material';
import NotificationsDrawer from 'pages/Dashboard/NotificationsDrawer';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { useQuery } from 'react-query';
import { healthRecordRefreshUrls } from 'utils/apiUrls';
import { unreadNotificationsCount } from 'pages/Dashboard/services/healthrecords.services';
import { trackMixpanelEvent } from 'utils/utilMethods';

const bounceKeyframe = keyframes`
  0% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  45% {
    transform: rotate(5deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const REFRESH_INTERVAL = 5000;
const REFRESH_DURATION = 2000;

export default function Header() {
  const theme = useTheme();
  const navigator = useNavigate();
  const {
    activeProvider,
    superAdminId,
    updateActiveProvider,
  } = React.useContext(FetchContext);

  const [showNotificationsDrawer, setShowNotificationsDrawer] = React.useState(false);
  const { http, providerId, isHrRefreshEnabled } = useHttp();
  const [refreshing, setRefreshing] = React.useState(false);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setRefreshing(true);
      setTimeout(() => setRefreshing(false), REFRESH_DURATION);
    }, REFRESH_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  const isSuperAdmin = !!superAdminId;

  const shouldFetchNotifications = !!providerId && isHrRefreshEnabled;

  const notificationsCount = useQuery({
    queryKey: [healthRecordRefreshUrls.unreadNotifications.queryUrl, providerId],
    queryFn: unreadNotificationsCount(http.get),
    enabled: shouldFetchNotifications,
    refetchInterval: shouldFetchNotifications ? 120000 : false });

  React.useEffect(() => {
    if (shouldFetchNotifications) {
      const interval = setInterval(() => {
        notificationsCount.refetch();
      }, 120000);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [notificationsCount, shouldFetchNotifications]);

  const handleActiveProviderClick = () => {
    updateActiveProvider({ providerId: superAdminId } as ProviderPractice);
    navigator('/providers-list');
  };

  const openNotificationDrawer = () => {
    setShowNotificationsDrawer(true);
    trackMixpanelEvent(MixpanelEventName.NOTIFCATION_BELL_CLICKED, {
      'unread count': (notificationsCount.data?.notifications.unread ?? 0).toString() });
  };

  const toggleRecordRefreshDrawer = (open: boolean) => {
    setShowNotificationsDrawer(open);
  };

  return (
    <>
      <NotificationsDrawer
        isOpen={showNotificationsDrawer}
        toggleDrawer={toggleRecordRefreshDrawer}
      />
      <AppBar
        position='fixed'
        sx={{
          maxHeight: theme.custom.appBarHeight,
          background: theme.palette.background.default,
        }}
      >
        <Toolbar disableGutters>
          <Grid container mx='12.5px' justifyContent='space-between'>
            <Grid item>
              <img
                height={theme.custom.logoHeight}
                width={theme.custom.logoWidth}
                src={LogoUrl.lg}
                alt=''
              />
            </Grid>
            <Grid item display='flex' alignItems='center'>
              {isSuperAdmin && activeProvider?.providerName && (
              <Tooltip title='Change active provider'>
                <Chip
                  sx={{
                    mr: 3,
                  }}
                  color='primary'
                  label={(
                    <div>
                      <b>
                        Active provider :
                      </b>
                      {' '}
                      {activeProvider.providerName}
                    </div>
                )}
                  variant='outlined'
                  onClick={handleActiveProviderClick}
                />
              </Tooltip>
              )}
              <Grid item display='flex' alignItems='center'>
                {shouldFetchNotifications && !notificationsCount.isLoading
                && notificationsCount.data && (
                  (notificationsCount?.data?.notifications?.unread as number) > 0 ? (
                    <IconButton sx={{ p: 0.5 }} onClick={openNotificationDrawer}>
                      <Badge
                        badgeContent={notificationsCount.data?.notifications.unread}
                        color='error'
                        showZero={false}
                        sx={{
                          '& .MuiBadge-badge': {
                            marginTop: '9px',
                            marginRight: '9px',
                          },
                        }}
                      >
                        <NotificationsIcon
                          sx={{ color: '#0000008A',
                            animation: refreshing ? `${bounceKeyframe} 1s ease-in-out infinite` : 'none',
                          }}
                          fontSize='large'
                        />
                      </Badge>
                    </IconButton>
                  ) : (
                    <IconButton sx={{ p: 0.5 }} onClick={openNotificationDrawer}>
                      <NotificationsNoneOutlinedIcon
                        sx={{ color: '#0000008A' }}
                        fontSize='medium'
                      />
                    </IconButton>
                  ))}
                <UserSettings />
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}
