import React, { useState } from 'react';
import { IconButton, Grid, Typography, Box } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';
import { useParams } from 'react-router-dom';
import Theme from 'theme';

const daysofWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

function AppointmentCalendar({
  selectedDate,
  setSelectedDate,
}: {
    selectedDate: Date | null;
    setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>;
  }) {
  const { id } = useParams();
  const today = new Date();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedYear, setSelectedYear] = useState(today.getFullYear());
  const [showYearSelection, setShowYearSelection] = useState(false);

  const nextSixteenYears = Array.from({ length: 16 }, (_, i) => today.getFullYear() + i);
  const maxSelectableYear = today.getFullYear() + 1;

  const handlePrevMonth = () => {
    setCurrentDate((prev) => {
      const newMonth = prev.getMonth() - 1;
      const newYear = newMonth < 0 ? prev.getFullYear() - 1 : prev.getFullYear();
      trackMixpanelEvent(MixpanelEventName.PREVIOUS_MONTH_BUTTON_CLICKED, {
        'patient id': id ?? '',
        'current month': prev.getMonth().toString(),
        'current year': prev.getFullYear().toString(),
      });
      return new Date(newYear, (newMonth + 12) % 12);
    });
  };

  const handleNextMonth = () => {
    setCurrentDate((prev) => {
      const newMonth = prev.getMonth() + 1;
      const newYear = newMonth > 11 ? prev.getFullYear() + 1 : prev.getFullYear();
      if (newYear > maxSelectableYear) {
        return prev;
      }
      trackMixpanelEvent(MixpanelEventName.NEXT_MONTH_BUTTON_CLICKED, {
        'patient id': id ?? '',
        'current month': prev.getMonth().toString(),
        'current year': prev.getFullYear().toString(),
      });
      return new Date(newYear, newMonth % 12);
    });
  };

  const handleYearChange = (year: number) => {
    if (year > maxSelectableYear) return;

    setSelectedYear(year);
    setCurrentDate(new Date(year, currentDate.getMonth()));
    setShowYearSelection(false);

    trackMixpanelEvent(MixpanelEventName.YEAR_SELECTED, {
      'patient id': id ?? '',
      year: year.toString(),
    });
  };


  const handleDateSelect = (date: Date) => {
    setSelectedDate(date);
    trackMixpanelEvent(MixpanelEventName.DATE_SELECTED_ON_APPOINTMENT_CALENDAR, {
      'patient id': id ?? '',
      'selected date': date.toDateString(),
    });
  };

  const getColor = (year: number, isDisabled: boolean) => {
    if (isDisabled) {
      return Theme.custom.colors.lightTextSecondary;
    }
    if (year === selectedYear) {
      return Theme.custom.colors.white;
    }
    return Theme.custom.colors.lightTextPrimary;
  };

  const getDaysInMonth = (
    year: number,
    month: number,
  ): number => new Date(year, month + 1, 0).getDate();
  const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
  const totalDays = getDaysInMonth(currentDate.getFullYear(), currentDate.getMonth());
  const daysArray = Array.from({ length: totalDays }, (_, i) => i + 1);

  return (
    <Box
      maxWidth={320}
      minHeight={310}
      maxHeight={400}
      padding={2}
      paddingLeft={2.25}
      paddingRight={2.25}
      borderRadius={1}
      marginTop={4}
      boxShadow='0px 8px 10px 1px #00000024, 0px 5px 5px -3px #00000033, 0px 3px 14px 2px #0000001F'
      style={{
        background: 'white',
      }}
    >
      <Grid container alignItems='center' justifyContent='space-between'>
        <Typography
          fontWeight={500}
          fontSize={16}
          style={{ display: 'flex', alignItems: 'center', gap: 10 }}
        >
          {currentDate.toLocaleString('default', { month: 'long' })}
          {' '}
          {currentDate.getFullYear()}
          <IconButton size='small' onClick={(e) => setShowYearSelection(!showYearSelection)}>
            {!showYearSelection ? (
              <ArrowDropDown sx={{ color: '#0000008A' }} />
            ) : (
              <ArrowDropUp sx={{ color: '#0000008A' }} />
            )}
          </IconButton>
        </Typography>
        {!showYearSelection && (
        <div>
          <IconButton onClick={handlePrevMonth} disabled={currentDate <= today}>
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton
            onClick={handleNextMonth}
            disabled={
              currentDate.getFullYear() > maxSelectableYear
              || (currentDate.getFullYear() === maxSelectableYear && currentDate.getMonth() === 11)
            }
            sx={{ marginLeft: 3, paddingRight: 0 }}
          >
            <KeyboardArrowRight />
          </IconButton>
        </div>
        )}
      </Grid>
      {showYearSelection ? (
        <Grid container spacing={2} justifyContent='center' marginTop={1}>
          {nextSixteenYears.map((year) => {
            const isDisabled = year > maxSelectableYear;
            return (
              <Grid item key={year} style={{ textAlign: 'center', width: '25%' }}>
                <Typography
                  variant='body1'
                  onClick={() => !isDisabled && handleYearChange(year)}
                  padding={1}
                  style={{
                    borderRadius: '4px',
                    backgroundColor: year === selectedYear ? Theme.custom.colors.primaryMain : 'transparent',
                    color: getColor(year, isDisabled),
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    pointerEvents: isDisabled ? 'none' : 'auto',
                  }}
                >
                  {year}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Grid container spacing={1} justifyContent='center' marginTop={2}>
          {daysofWeek.map((day) => (
            <Grid item key={day} width='14.28%' textAlign='center' paddingLeft={0}>
              <Typography variant='caption' fontSize={12} color='#00000061'>{day}</Typography>
            </Grid>
          ))}
          {Array.from({ length: firstDay }).map((_, i) => (
            <Grid item key={`empty-${currentDate.getFullYear()}-${currentDate.getMonth()}-${Math.random()}`} width='14.28%' />
          ))}
          {daysArray.map((day) => {
            const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
            const isToday = date.toDateString() === today.toDateString();
            const isSelected = selectedDate && date.toDateString() === selectedDate.toDateString();
            const isPast = date.getTime() < new Date(today.setHours(0, 0, 0, 0)).getTime();
            let textColor;
            if (isPast) {
              textColor = Theme.custom.colors.lightTextSecondary;
            }
            else if (isToday && !isSelected) {
              textColor = Theme.custom.colors.primaryMain;
            }
            else if (isSelected) {
              textColor = Theme.custom.colors.white;
            }
            else {
              textColor = Theme.custom.colors.lightTextPrimary;
            }

            return (
              <Grid item key={day} width='14.28%' textAlign='center' paddingLeft={0} paddingTop={0}>
                <Typography
                  variant='body1'
                  onClick={() => !isPast && handleDateSelect(date)}
                  style={{
                    padding: '8px',
                    width: 40,
                    height: 40,
                    borderRadius: isToday && !isSelected ? '0%' : '50%',
                    border: isToday && !isSelected ? `1px solid ${Theme.custom.colors.primaryMain}` : 'none',
                    backgroundColor: isSelected ? Theme.custom.colors.primaryMain : 'transparent',
                    color: textColor,
                    cursor: isPast ? 'not-allowed' : 'pointer',
                  }}
                >
                  {day}
                </Typography>
              </Grid>
            );
          })}
          {/* Add empty grid items to complete the last row */}
          {Array.from({ length: (7 - ((firstDay + totalDays) % 7)) % 7 }).map((_, i) => (
            <Grid item key={`empty-end-${Math.random()}`} width='14.28%' />
          ))}
        </Grid>
      )}
    </Box>
  );
}

export default AppointmentCalendar;
