import { ONBOARDING_STATUS, PATIENT_LIST_VIEW } from 'pages/Dashboard/enums';
import { Status } from 'pages/Dashboard/types';
import { PatientObj } from 'pages/Dashboard/types/patient.types';
import * as React from 'react';

// TODO figure typing for this based on backend
export type dayType = '30' | '15' | '7';

export const dayOptions = {
  30: 'last 30 days',
  15: 'last 15 days',
  7: 'last 7 days',
};

type TRefetchPatientsCtx = {
  refetchPatients: () => void;
}

interface TPatientCtx extends Partial<PatientObj> {
  cb: () => void;
  setCb: React.Dispatch<React.SetStateAction<() => void>>;
  recordingStatus: Status;
  setRecordingStatus: React.Dispatch<React.SetStateAction<Status>>;
  isEditingNotes: boolean;
  setIsEditingNotes: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RefetchPatientsCtx = React.createContext<TRefetchPatientsCtx | null>(null);

export const PatientCtx = React.createContext<TPatientCtx>({} as TPatientCtx);

export const PATIENT_VIEW_STATUS_MAP: {
  [key in PATIENT_LIST_VIEW]: ONBOARDING_STATUS[] | undefined } = {
    [PATIENT_LIST_VIEW.PENDING]: [
      ONBOARDING_STATUS.PENDING_INVITE_DELIVERY,
      ONBOARDING_STATUS.PENDING_PATIENT_SIGNUP,
      ONBOARDING_STATUS.PREPARING_INVITE_DELIVERY,
    ],
    [PATIENT_LIST_VIEW.ERRORS]: [
      ONBOARDING_STATUS.DELIVERY_FAILED,
    ],
    [PATIENT_LIST_VIEW.READY]: [
      ONBOARDING_STATUS.SUCCESS,
    ],
  };
