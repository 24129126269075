import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import { ReactComponent as SessionRecording } from 'assets/SessionRecording.svg';
import NoteTakerWrapper from 'pages/Dashboard/components/SessionRecording/NoteTakerWrapper';

type SessionRecordingConsentProps = {
  handleIUnderstandClicked: () => void;
  setIsDrawerOpen: (value: boolean) => void;
};

function Consent({
  handleIUnderstandClicked,
  setIsDrawerOpen,
}: SessionRecordingConsentProps) {
  return (
    <NoteTakerWrapper setIsDrawerOpen={setIsDrawerOpen}>
      <Box textAlign='center' py={6} px={3} display='flex' flexDirection='column' alignItems='center' maxWidth={600}>
        <Box width={172} height={172}>
          <SessionRecording width='100%' height='100%' preserveAspectRatio='none' />
        </Box>
        <Typography variant='h6' mt={7} mb={3}>
          Patient consent required
        </Typography>
        <Typography variant='body1' mb={7}>
          By clicking &quot;I understand,&quot; you confirm that you have obtained the necessary
          consent from all parties to record this appointment session.
        </Typography>
        <Button variant='contained' color='primary' onClick={handleIUnderstandClicked}>
          I UNDERSTAND
        </Button>
      </Box>
    </NoteTakerWrapper>
  );
}

export default Consent;
