import { MixpanelEventName } from 'utils/constants';
import * as Sentry from '@sentry/react';
import { mixpanelEventInstance, mixpanelSessionReplay } from 'pages/Rewards/mixpanelInstances';

export const logSentryError = (
  error: string,
  tags?: Record<string, string>,
) => {
  Sentry.withScope((scope) => {
    scope.setTags({
      ...tags ?? {},
    });
    Sentry.captureException(error);
  });
};

export const logSentryInfo = (
  message: string,
  tags?: Record<string, string>,
) => {
  Sentry.withScope((scope) => {
    scope.setTags({
      ...tags ?? {},
    });
    scope.setLevel('info');
    Sentry.captureMessage(message);
  });
};

export const trackMixpanelEvent = (
  eventName: MixpanelEventName,
  payload?: Record<string, string | Record<string, string>|boolean>,
  trackSentryInfo = false,
) => {
  mixpanelEventInstance.track(eventName, { 'datetime clicked': new Date().toISOString(), ...payload });
  mixpanelSessionReplay.track(eventName, { 'datetime clicked': new Date().toISOString(), ...payload });

  if (trackSentryInfo) {
    logSentryInfo(eventName, payload as Record<string, string>);
  }
};

export const getBrowserName = () => {
  const { userAgent } = navigator;
  let browserName = 'Unknown';

  if (userAgent.indexOf('Firefox') > -1) {
    browserName = 'Firefox';
  }
  else if (userAgent.indexOf('Trident') > -1) {
    browserName = 'Internet Explorer';
  }
  else if (userAgent.indexOf('Edge') > -1) {
    browserName = 'Edge';
  }
  else if (userAgent.indexOf('Chrome') > -1) {
    browserName = 'Chrome';
  }
  else if (userAgent.indexOf('Safari') > -1) {
    browserName = 'Safari';
  }

  return browserName;
};

export const isValidEmail = (email: string) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
};

export const isValidPhoneNumber = (phoneNumber: string) => {
  const phoneNumberPattern = /^\((?!1|0)\d{3}\)\d{3}-\d{4}$/;
  return phoneNumberPattern.test(phoneNumber);
};

export default trackMixpanelEvent;


