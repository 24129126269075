import { useAuth0 } from '@auth0/auth0-react';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import useConfirm from 'hooks/use-confirm';
import * as React from 'react';
import { auth } from 'utils/spaUrls';

export default function Logout() {
  const { logout } = useAuth0();
  const onLogout = React.useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    logout({ returnTo: `${window.location.origin}${auth.login}` });
  }, [logout]);

  const { showConfirm, ConfirmDialog } = useConfirm();

  const handleLogout = () => {
    showConfirm({
      message: 'Are you sure you want to logout?',
      onConfirm: onLogout,
    });
  };

  return (
    <>
      <ConfirmDialog />
      <MenuItem onClick={handleLogout}><Typography color='primary'>Logout</Typography></MenuItem>
    </>
  );
}
