

import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useHttp } from 'hooks/use-fetch';

export default function useConfirm() {
  const { hasUnsavedChanges } = useHttp();
  const [confirmState, setConfirmState] = React.useState({ } as {
    isOpen: boolean,
    message: string,
    onConfirm: () => void,
  });

  const showConfirm = ({ message, onConfirm }: { message: string, onConfirm: () => void }) => {
    if (hasUnsavedChanges) {
      setConfirmState({
        isOpen: true,
        message,
        onConfirm,
      });
    }
    else {
      onConfirm();
    }
  };

  const hideConfirm = () => {
    setConfirmState({
      ...confirmState,
      isOpen: false,
    });
  };

  function ConfirmDialog() {
    return (
      <Dialog
        open={confirmState.isOpen}
        onClose={hideConfirm}
      >
        <DialogTitle>You have unsaved changes</DialogTitle>
        <DialogContent>
          <DialogContentText>{confirmState.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { hideConfirm(); }} color='primary'>
            No
          </Button>
          <Button onClick={() => { confirmState.onConfirm(); hideConfirm(); }} color='primary' autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return { showConfirm, ConfirmDialog };
}
