import { EditTranscriptPayload, GenerateNotesFromTranscriptPayload, GetDestinationUrlsResponse, GetMetadataResponse, GetPDFUrlResponse, GetSessionListResponse, SendTranscriptFeedbackPayload } from 'pages/Dashboard/types';
import { sessionRecordingUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

export const fetchMetadata = (method: HttpType['get'], providerId: string, patientId: string): Promise<GetMetadataResponse> => method(sessionRecordingUrls.getMetadata.apiUrl(providerId, patientId));

export const fetchSessionList = (method: HttpType['get'], providerId: string, patientId: string, queryParams?: string): Promise<GetSessionListResponse> => method(sessionRecordingUrls.getSessions.apiUrl(providerId, patientId, queryParams ?? ''));

export const editTranscript = (method: HttpType['put'], providerId: string, patientId: string, sessionId: string, payload: EditTranscriptPayload) => method(sessionRecordingUrls.editTranscript.apiUrl(providerId, patientId, sessionId), payload);

export const sendFeedbackForTranscript = (method: HttpType['post'], providerId: string, patientId: string, sessionId: string, payload: SendTranscriptFeedbackPayload) => method(sessionRecordingUrls.sendTranscriptFeedback.apiUrl(providerId, patientId, sessionId), payload);

export const generateNotesForTranscript = (method: HttpType['post'], providerId: string, patientId: string, payload: GenerateNotesFromTranscriptPayload) => method(sessionRecordingUrls.generateNotes.apiUrl(providerId, patientId), payload);

export const fetchDestinationUrls = (method: HttpType['get'], providerId: string, patientId: string, sessionId?: string): Promise<GetDestinationUrlsResponse> => method(sessionRecordingUrls.getDestinationUrls.apiUrl(providerId as string, patientId as string, sessionId as string), { retryOnOffline: 'true' });

export const fetchSessionAuthToken = (method: HttpType['get'], providerId: string, patientId: string, sessionId?: string) => method(sessionRecordingUrls.getSessionAuthToken.apiUrl(providerId, patientId, sessionId), { retryOnOffline: 'true' });

export const fetchPDFUrl = (method: HttpType['get'], providerId: string, patientId: string, sessionId: string):Promise<GetPDFUrlResponse> => method(sessionRecordingUrls.getPDFUrl.apiUrl(providerId, patientId, sessionId));
