import * as React from 'react';
import { Box, Tab, Tabs as MuiTabs } from '@mui/material';
import { SideNavCtx } from 'components/Layouts/constants';
import Theme from 'theme';
import { PADDING_LEFT, PADDING_RIGHT } from 'components/Layouts/Borders';


const styles = {
  position: 'relative',
  transition: 'top 0.3s',
};

const stickyStyles = {
  position: 'fixed',
  top: Theme.custom.appBarHeight,
  zIndex: 1000,
  pr: PADDING_RIGHT,
  pl: PADDING_LEFT,
  backgroundColor: Theme.custom.backgroundColor,
  left: '0',
  width: '0',
};

export type TabContext = {
  header: string;
  headerIcon?: React.ReactElement;
  content?: React.ReactNode;
};

interface TabsProps {
  tabContext: TabContext[];
  value: number | string;
  variant?: 'standard' | 'fullWidth';
  activeStyles?: React.CSSProperties;
  height?: number;
  setValue: (value: number | string) => void;
}

export function Tabs({
  tabContext,
  value,
  variant = 'fullWidth',
  activeStyles,
  height = 42,
  setValue,
}: TabsProps) {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: variant === 'fullWidth' ? '100%' : 'auto' }}>
      <MuiTabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={activeStyles ? { sx: { display: 'none' } } : {}}
        sx={{
          minHeight: height,
          borderRadius: 1,
          bgcolor: Theme.custom.colors.backgroundColorSecondary,
          '& .MuiTabs-flexContainer': {
            height: '100%',
            alignItems: 'center',
          },
        }}
      >
        {tabContext.map((tabs, index) => (
          <Tab
            icon={tabs.headerIcon}
            label={tabs.header}
            key={tabs.header.toLowerCase()}
            iconPosition='start'
            sx={{
              marginX: 2,
              paddingX: 1,
              minHeight: 30,
              height: '100%',
              ...(index === value ? activeStyles ?? {} : {}),
            }}
          />
        ))}
      </MuiTabs>
    </Box>
  );
}

interface TabComponentProps {
  tabContext: TabContext[];
  tabsHeight?: number;
  stickHeader?: boolean;
  renderBelowHeader?: (selectedTab: number) => React.ReactElement | null;
  onTabChange?: (selectedTab: number) => void;
}

export default function TabComponent({
  tabContext,
  tabsHeight = 42,
  stickHeader = false,
  renderBelowHeader,
  onTabChange,
}: TabComponentProps) {
  const { scrollPosition, drawerWidth } = React.useContext(SideNavCtx);

  const [value, setValue] = React.useState(0);
  const [distanceFromTop, setDistanceFromTop] = React.useState(0);

  const cardRef = React.useRef<HTMLDivElement | null>(null);
  const boxRef = React.useRef<HTMLDivElement | null>(null);
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (cardRef.current) {
      const { top } = cardRef.current.getBoundingClientRect();
      setDistanceFromTop(top);
    }
  }, []);

  const isSticky = React.useMemo(
    () => ((scrollPosition || -Infinity) > distanceFromTop - 18 - tabsHeight) && stickHeader,
    [scrollPosition, distanceFromTop, tabsHeight, stickHeader],
  );

  const offset = boxRef?.current?.offsetHeight ?? 0;
  const pt = isSticky ? `${offset}px` : 0;

  React.useEffect(() => {
    if (containerRef.current && isSticky) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isSticky, value]);

  stickyStyles.left = drawerWidth as string;
  stickyStyles.width = `calc(100% - ${drawerWidth})`;

  const onTabChangeHandler = (selectedTab: number) => {
    setValue(selectedTab);
    if (onTabChange) {
      onTabChange(selectedTab);
    }
  };

  return (
    <Box display='flex' flexDirection='column' overflow='auto'>
      <Box ref={boxRef} sx={isSticky ? { ...styles, ...stickyStyles } : styles}>
        <Box ref={cardRef}>
          <Tabs
            tabContext={tabContext}
            value={value}
            setValue={(v) => onTabChangeHandler(v as number)}
            height={tabsHeight}
          />
        </Box>
        <Box>
          {renderBelowHeader && renderBelowHeader(value)}
        </Box>
      </Box>
      <Box pt={pt} ref={containerRef} display='flex' flexDirection='column' overflow='auto'>
        {tabContext.map((tabs, index) => (
          value === index ? <Box key={tabs.header} display='flex' flexDirection='column' overflow='auto'>{tabs.content}</Box> : null))}
      </Box>
    </Box>
  );
}
