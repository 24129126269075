import { useEffect, useCallback } from 'react';

const useConfirmTabClose = (condition: boolean) => {
  const handleBeforeUnload = useCallback((event: BeforeUnloadEvent) => {
    if (!condition) return;
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.returnValue = '';
  }, [condition]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);
};

export default useConfirmTabClose;
