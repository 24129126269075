import { AutoAwesome } from '@mui/icons-material';
import { Box, Skeleton } from '@mui/material';
import TabComponent, { TabContext } from 'components/Common/TabComponent';
import { ToastProps } from 'components/Common/Toast';
import { useHttp } from 'hooks/use-fetch';
import AINotesTab from 'pages/Dashboard/components/SessionRecording/AINotesTab';
import NoteTakerWrapper from 'pages/Dashboard/components/SessionRecording/NoteTakerWrapper';
import TranscriptTab from 'pages/Dashboard/components/SessionRecording/TranscriptTab';
import { fetchSessionList } from 'pages/Dashboard/services/sessionrecording.services';
import { GetMetadataResponse, Session } from 'pages/Dashboard/types';
import React from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { MixpanelEventName } from 'utils/constants';
import { logSentryError, trackMixpanelEvent } from 'utils/utilMethods';

interface ResultsProps {
  generatedNotes: Session;
  isDrawerOpen: boolean;
  metadata: GetMetadataResponse;
  isLoadingMetadata: boolean;
  setIsDrawerOpen: (isDrawerOpen: boolean) => void;
  setToastProps: React.Dispatch<React.SetStateAction<ToastProps | null>>;
}

function Results({
  generatedNotes,
  isDrawerOpen,
  metadata,
  isLoadingMetadata,
  setIsDrawerOpen,
  setToastProps,
}: ResultsProps) {
  const { id } = useParams();
  const patientId = id ?? '';
  const { providerId, http } = useHttp();
  const [session, setSession] = React.useState<Session>({} as Session);

  const { mutate: getSessionDetails, isLoading: isLoadingSessionDetails } = useMutation(
    () => fetchSessionList(http.get, providerId, patientId, `?session-id=${generatedNotes.sessionId}`),
    {
      onSuccess: (data) => {
        setSession(data?.sessions?.[0]);
      },
      onError: (err) => {
        logSentryError(
          `Error fetching session details - ${
            err instanceof Error ? err.message : JSON.stringify(err)
          }`,
        );
      },
    },
  );

  React.useEffect(() => {
    const shouldSetSession = !session.sessionId && !isLoadingSessionDetails;
    if (isDrawerOpen && generatedNotes.sessionId && !generatedNotes.notes && shouldSetSession) {
      getSessionDetails();
    }
    else if (shouldSetSession) {
      setSession(generatedNotes);
    }
  }, [session.sessionId, generatedNotes, getSessionDetails, isDrawerOpen, isLoadingSessionDetails]);

  const feedbackItems = metadata?.feedbackBuckets ?? [];
  const templateItems = metadata?.templates ?? [];

  const updateSession = (updatedSession: Session) => {
    setSession(updatedSession);
  };

  const configureTabs: TabContext[] = [
    {
      header: 'AI NOTES',
      headerIcon: <AutoAwesome />,
      content: (
        <AINotesTab
          session={session}
          feedbackItems={feedbackItems}
          templateItems={templateItems}
          setSession={updateSession}
          setToastProps={setToastProps}
        />
      ),
    },
    {
      header: 'TRANSCRIPT',
      content: <TranscriptTab transcript={session.transcript} />,
    },
  ];

  const onTabChange = (selectedTab: number) => {
    trackMixpanelEvent(MixpanelEventName.SESSION_TAB_SELECTED, {
      tab: configureTabs[selectedTab].header,
      session: session.sessionId,
    });
  };

  if (isLoadingMetadata || isLoadingSessionDetails) {
    return (
      <NoteTakerWrapper
        title={session.sessionName || generatedNotes.sessionName}
        setIsDrawerOpen={setIsDrawerOpen}
      >
        <Skeleton
          variant='rectangular'
          height={600}
          sx={{ my: 3, mx: 3 }}
          data-testid='skeletons'
        />
      </NoteTakerWrapper>
    );
  }

  return (
    <NoteTakerWrapper
      title={session.sessionName || generatedNotes.sessionName}
      setIsDrawerOpen={setIsDrawerOpen}
    >
      <Box p={3} display='flex' flexDirection='column' overflow='auto'>
        <TabComponent tabContext={configureTabs} tabsHeight={48} onTabChange={onTabChange} />
      </Box>
    </NoteTakerWrapper>
  );
}

export default Results;
