import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useMutation, useQuery } from 'react-query';
import { GetDestinationUrlsResponse, GetMetadataResponse, Session, Status } from 'pages/Dashboard/types';
import { useHttp } from 'hooks/use-fetch';
import { useParams } from 'react-router-dom';
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import Results from 'pages/Dashboard/components/SessionRecording/Results';
import { fetchDestinationUrls, fetchMetadata, fetchSessionAuthToken, generateNotesForTranscript } from 'pages/Dashboard/services/sessionrecording.services';
import theme from 'theme';
import { AudioConfig, ProfanityOption, ResultReason, SpeechConfig, SpeechRecognizer } from 'microsoft-cognitiveservices-speech-sdk';
import { sessionRecordingUrls } from 'utils/apiUrls';
import { logSentryError, trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';
import { formatISO } from 'date-fns';
import NoteTakerWrapper from 'pages/Dashboard/components/SessionRecording/NoteTakerWrapper';
import useConfirmTabClose from 'pages/Dashboard/components/SessionRecording/useConfirmTabClose';
import { retryFetch } from 'utils/http';
import SilenceDetection from 'pages/Dashboard/components/SessionRecording/SilenceDetection';
import { ToastProps } from 'components/Common/Toast';

const subscriptionKey = 'BMAZB8vdnn3ztQKlKrISGYnrITjJRRMLmxP64L9cAPvGBspSlMChJQQJ99BAACYeBjFXJ3w3AAAYACOGXNdU';
const region = 'eastus';
const speechRecognitionLanguage = 'en-US';

const useOnlineStatus = () => {
  const [isOffline, setIsOffline] = React.useState(!navigator.onLine);
  const [backOnline, setBackOnline] = React.useState(false);

  React.useEffect(() => {
    const handleOnline = () => {
      setIsOffline(false);
      setBackOnline((prev) => prev === false);
      trackMixpanelEvent(MixpanelEventName.SESSION_ONLINE_STATUS_UPDATED, {
        online: true,
      });
    };
    const handleOffline = () => {
      setIsOffline(true);
      trackMixpanelEvent(MixpanelEventName.SESSION_ONLINE_STATUS_UPDATED, {
        online: false,
      });
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return [isOffline, backOnline];
};

const getIntervals = (first: MediaRecorder, second: MediaRecorder) => {
  const intervals: { classInstance: MediaRecorder; start: number; stop: number }[] = [];
  const duration = 3; // duration of each interval in minutes
  const overlap = 0.5; // overlap duration in minutes
  const totalIntervals = 50; // total number of intervals needed

  Array.from({ length: totalIntervals }).forEach((_, i) => {
    const classInstance = i % 2 === 0 ? first : second;
    const start = i * (duration - overlap);
    const stop = start + duration;
    intervals.push({ classInstance, start, stop });
  });
  return intervals;
};

type NoteTakerProps = {
  status: Status;
  timer: { time: string; sec: number };
  isDrawerOpen: boolean;
  volume: number;
  isGeneratingTranscript: boolean;
  setIsGeneratingTranscript: React.Dispatch<React.SetStateAction<boolean>>;
  setStatus: (status: Status) => void;
  setVolume: (volumeLevel: number) => void;
  setTimer: React.Dispatch<React.SetStateAction<number>>;
  setIsDrawerOpen: (isDrawerOpen: boolean) => void;
  fetchSessions: ({ offset }: {offset: number}) => void;
  setToastProps: React.Dispatch<React.SetStateAction<ToastProps | null>>;
}

export default function NoteTaker({
  status,
  timer,
  isDrawerOpen,
  volume,
  isGeneratingTranscript,
  setIsGeneratingTranscript,
  setStatus,
  setVolume,
  setTimer,
  setIsDrawerOpen,
  fetchSessions,
  setToastProps,
}: NoteTakerProps) {
  const { http, providerId, isFastAPIEnabled, setHasUnsavedChanges } = useHttp();
  const { id: patientId } = useParams();
  const [isOffline, isOnline] = useOnlineStatus();

  useConfirmTabClose(status !== Status.DEFAULT || isGeneratingTranscript);

  const [displayText, setDisplayText] = React.useState({} as Record<string, string>);
  const [audioInputDevices, setAudioInputDevices] = React.useState<MediaDeviceInfo[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = React.useState<string | null>(null);
  const [generatedNotes, setGeneratedNotes] = React.useState<Session>({} as Session);
  const [isMicrophonePermissionDenied,
    setIsMicrophonePermissionDenied] = React.useState<boolean>(false);
  const [mediaRecorders, setMediaRecorders] = React.useState(
    {} as { first: MediaRecorder; second?: MediaRecorder, third?: MediaRecorder },
  );
  const [transcriptChunkGenerating, setTranscriptChunkGenerating] = React.useState(
    {} as Record<string, boolean>,
  );

  const [destinationUrls, setDestinationUrls] = React.useState<
    GetDestinationUrlsResponse | null
  >(null);
  const [recognizer, setRecognizer] = React.useState<SpeechRecognizer | null>(null);
  const [showWarning, setShowWarning] = React.useState(false);


  const audioChunks = React.useRef<Blob[]>([]);
  const isOnlineRef = React.useRef(!isOffline);
  const streamRef = React.useRef<MediaStream | null>(null);

  const timerInterval = React.useRef<NodeJS.Timeout | null>(null);
  const audioUploadTimer = React.useRef<NodeJS.Timeout[] | null>([]);
  const volumeInterval = React.useRef<NodeJS.Timeout | null>(null);

  const resetIntervals = React.useCallback(() => {
    if (audioUploadTimer.current) {
      audioUploadTimer.current.forEach(clearInterval);
    }
    if (volumeInterval.current) clearInterval(volumeInterval.current);
    if (timerInterval.current) { clearInterval(timerInterval.current); }
  }, []);

  const updateStatusAsError = React.useCallback(() => {
    if (isOnlineRef.current) {
      setStatus(Status.ERROR);
      setIsGeneratingTranscript(false);
      resetIntervals();
    }
  }, [resetIntervals, setIsGeneratingTranscript, setStatus]);

  const { mutateAsync: generateNotes } = useMutation({
    mutationFn: ({ sessionId }: { sessionId: string }) => generateNotesForTranscript(http.post, providerId, patientId ?? '', {
      transcript: '',
      templateId: metadata?.templates?.[0].id ?? '',
      sessionId,
      patientId: patientId ?? '',
      sessionDurationSec: timer.sec,
    }),
    onSuccess: (data: Session) => {
      setGeneratedNotes(data);
      setIsGeneratingTranscript(false);
      fetchSessions({ offset: 0 });
    },
    onError: (err) => {
      updateStatusAsError();
      logSentryError(`Error generating notes - ${err instanceof Error ? err.message : JSON.stringify(err)}`);
    },
    retry: 2,
  });

  const { mutateAsync: getSessionAuthToken } = useMutation({
    mutationFn: () => fetchSessionAuthToken(http.get, providerId, patientId ?? '', destinationUrls?.sessionId),
    onError: (err) => {
      updateStatusAsError();
      logSentryError(`Error fetching session auth token - ${err instanceof Error ? err.message : JSON.stringify(err)}`);
    },
    retry: 2,
  });

  const { mutateAsync: getDestinationUrls, isLoading: isLoadingDestinationUrls } = useMutation({
    mutationFn: () => fetchDestinationUrls(http.get, providerId, patientId ?? '', destinationUrls?.sessionId),
    onSuccess: (data) => {
      if (destinationUrls?.sessionId) {
        setDestinationUrls(data);
      }
      else {
        setDestinationUrls({
          sessionId: data.sessionId,
        } as GetDestinationUrlsResponse);
      }
    },
    onError: (err) => {
      updateStatusAsError();
      logSentryError(`Error fetching destination urls - ${err instanceof Error ? err.message : JSON.stringify(err)}`);
    },
  });

  React.useEffect(() => {
    if (isDrawerOpen && !destinationUrls?.sessionId && !isLoadingDestinationUrls) {
      getDestinationUrls();
    }
  }, [destinationUrls?.sessionId, getDestinationUrls, isDrawerOpen, isLoadingDestinationUrls]);

  const { data: metadata, isLoading: isLoadingMetadata } = useQuery(
    [sessionRecordingUrls.getMetadata.queryUrl],
    () => fetchMetadata(http.get, providerId, patientId ?? ''),
    {
      onError: (err) => {
        updateStatusAsError();
        logSentryError(`Error fetching metadata - ${err instanceof Error ? err.message : JSON.stringify(err)}`);
      },
      retry: 2,
    },
  );

  const uploadContentToDestination = React.useCallback(async (urls: GetDestinationUrlsResponse) => {
    try {
      const currentTime = formatISO(new Date());
      if (urls?.transcript) {
        const finalTranscript = Object.keys(displayText)
          .sort() // Sort the keys alphabetically
          .reduce((result, key) => `${result ? `${result} ::: ` : ''}${displayText[key]}`, '');
        const transcriptUploadResponse = await retryFetch(urls?.transcript, {
          method: 'PUT',
          headers: {
            'Content-Type': 'text/plain',
            'current-time': currentTime,
          },
          body: finalTranscript,
        });
        if (transcriptUploadResponse?.status === 200) {
          await generateNotes({
            sessionId: urls.sessionId,
          });
          setTimer(0);
        }
        else {
          updateStatusAsError();
          logSentryError(`Error uploading transcript - ${transcriptUploadResponse?.status}`);
        }
      }

      if (urls?.audio) {
        const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
        const audioUploadResponse = await retryFetch(urls?.audio, {
          method: 'PUT',
          headers: {
            'Content-Type': 'audio/wav',
            'current-time': currentTime,
          },
          body: audioBlob,
        });

        if (audioUploadResponse?.status !== 200) {
          updateStatusAsError();
          logSentryError(`Error uploading audio - ${audioUploadResponse?.status}`);
        }
      }
    }
    catch (err) {
      updateStatusAsError();
      logSentryError(`Error uploading content to destination - ${err instanceof Error ? err.message : JSON.stringify(err)}`);
    }
  }, [
    displayText,
    generateNotes,
    updateStatusAsError,
    setTimer,
  ]);

  React.useEffect(() => {
    if (!isDrawerOpen && (generatedNotes.sessionId || status === Status.ERROR)) {
      setStatus(Status.DEFAULT);
      setIsGeneratingTranscript(false);
      setGeneratedNotes({} as Session);
      setDisplayText({});
      setMediaRecorders(
        {} as { first: MediaRecorder; second?: MediaRecorder; third?: MediaRecorder },
      );
      setDestinationUrls(null);
      setRecognizer(null);
      audioChunks.current = [];
      setTimer(0);
    }
  }, [generatedNotes, isDrawerOpen, setIsGeneratingTranscript, setStatus, setTimer, status]);

  React.useEffect(() => {
    const noTranscriptChunksGenerating = Object.values(
      transcriptChunkGenerating,
    ).every((value) => !value);

    if (
      noTranscriptChunksGenerating
      && destinationUrls?.transcript
      && isGeneratingTranscript
    ) {
      uploadContentToDestination(destinationUrls as GetDestinationUrlsResponse);
    }
  }, [
    destinationUrls,
    isGeneratingTranscript,
    transcriptChunkGenerating,
    uploadContentToDestination,
  ]);

  const getPermission = React.useCallback(async () => {
    const permissionStatus = await navigator.permissions.query({
      name: 'microphone' as PermissionName,
    });

    const isPermissionDenied = permissionStatus.state === 'denied';
    setIsMicrophonePermissionDenied(isPermissionDenied);
    if (isPermissionDenied) return;
    try {
      const res = await navigator.mediaDevices.getUserMedia({ audio: true });
      if (res.id) {
        const updatedDevices = await navigator.mediaDevices.enumerateDevices();
        const updatedAudioDevices = updatedDevices.filter((device) => device.kind === 'audioinput');
        setAudioInputDevices(updatedAudioDevices);
        setSelectedDeviceId(updatedAudioDevices[0]?.deviceId);
      }
      else {
        setIsMicrophonePermissionDenied(true);
      }
    }
    catch (err) {
      logSentryError(`Error getting microphone permission - ${err instanceof Error ? err.message : JSON.stringify(err)}`);
      setIsMicrophonePermissionDenied(true);
    }
  }, []);

  const uploadFile = React.useCallback(async (audioFile: File) => {
    if (!transcriptChunkGenerating[audioFile.lastModified]) {
      setTranscriptChunkGenerating((prev) => ({
        ...prev,
        [audioFile.lastModified]: true,
      }));

      const res = await getSessionAuthToken();
      const { url, token } = res;
      const formData = new FormData();
      formData.append('audio', audioFile);
      formData.append(
        'definition',
        JSON.stringify({
          locales: ['en-US'],
        }),
      );

      try {
        const response = await fetch(
          url,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          },
        );

        if (!response.ok) {
          throw new Error(`Error with the fast API transcription service - ${response.status}`);
        }

        const result = await response.json();
        const transcription = result.combinedPhrases[0].text;
        setDisplayText((prev) => ({
          ...prev,
          [audioFile.lastModified]: transcription,
        }));
        setTranscriptChunkGenerating((prev) => ({
          ...prev,
          [audioFile.lastModified]: false,
        }));
      }
      catch (err) {
        updateStatusAsError();
        logSentryError(`Error uploading audio file - ${err instanceof Error ? err.message : JSON.stringify(err)}`);
      }
    }
  }, [getSessionAuthToken, transcriptChunkGenerating, updateStatusAsError]);

  const handleRecordingUpload = React.useCallback(async (chunks: Blob[]) => {
    const audioFile = new File(chunks, 'recording.wav', {
      type: 'audio/wav',
    });

    uploadFile(audioFile);
  }, [uploadFile]);

  React.useEffect(() => {
    if (isOffline) {
      setShowWarning((prev) => prev === false);
    }

    if (isOnline) {
      setShowWarning(false);
    }
  }, [isOnline, isOffline]);

  React.useEffect(() => {
    setHasUnsavedChanges(
      [Status.RECORDING, Status.PAUSED].includes(status) && !isGeneratingTranscript,
    );
  }, [status, isGeneratingTranscript, setHasUnsavedChanges]);

  React.useEffect(() => {
    if (status === Status.PAUSED && mediaRecorders.first && !isFastAPIEnabled && recognizer) {
      recognizer.stopContinuousRecognitionAsync(
        () => {
          setRecognizer(null);
        },
        (err) => {
          updateStatusAsError();
          logSentryError(`Error stopping continuous recognition - ${err}`);
        },
      );
      resetIntervals();
    }
  }, [isFastAPIEnabled, mediaRecorders, recognizer, resetIntervals, updateStatusAsError, status]);

  React.useEffect(() => {
    if ((status === Status.PAUSED) && mediaRecorders.third && isFastAPIEnabled) {
      mediaRecorders?.first?.stop();
      const mediaRecorder = mediaRecorders.second?.state === 'recording' ? mediaRecorders.second : mediaRecorders.third;
      mediaRecorder.stop();
      mediaRecorder.onstop = () => {
        resetIntervals();
      };
    }
  }, [isFastAPIEnabled, mediaRecorders, resetIntervals, status]);

  React.useEffect(() => {
    async function fetchAudioInputDevices() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioDevices = devices.filter((device) => device.kind === 'audioinput');
        setAudioInputDevices(audioDevices);
        const numberOfAudioDevices = audioDevices.filter(
          (device) => device.kind === 'audioinput' && device.deviceId,
        ).length;
        if (numberOfAudioDevices === 0) {
          getPermission();
        }
        else {
          setSelectedDeviceId(audioDevices[0]?.deviceId);
        }
      }
      catch (err) {
        updateStatusAsError();
        logSentryError(`Error fetching audio input devices - ${err instanceof Error ? err.message : JSON.stringify(err)}`);
      }
    }

    if (isDrawerOpen) {
      fetchAudioInputDevices();
    }
  }, [isDrawerOpen, getPermission, updateStatusAsError]);

  const startAndStop = (intervals: {
    classInstance: MediaRecorder;
    start: number;
    stop: number;
  }[]) => {
    intervals.forEach(({ classInstance, start, stop }) => {
      if (audioUploadTimer.current) {
      // Schedule the start function at the given start time
        audioUploadTimer.current.push(setTimeout(() => {
          classInstance.start();
        }, start * 60000));

        // Schedule the stop function at the given stop time
        audioUploadTimer.current.push(setTimeout(() => {
          classInstance.stop();
          // eslint-disable-next-line no-param-reassign
          classInstance.onstop = () => null;
        }, stop * 60000));
      }
    });
  };

  const startRecording = async () => {
    try {
      const audioCtx = new window.AudioContext();

      if (status === Status.PAUSED && (mediaRecorders?.second?.state === 'inactive' || mediaRecorders?.third?.state === 'inactive') && isFastAPIEnabled) {
        trackMixpanelEvent(MixpanelEventName.RESUME_RECORDING_BUTTON_CLICKED, {
          time: timer.time,
          session: destinationUrls?.sessionId ?? '',
        });
        setStatus(Status.RECORDING);
        startAndStop(getIntervals(
          mediaRecorders?.second as MediaRecorder,
          mediaRecorders?.third as MediaRecorder,
        ));
        timerInterval.current = setInterval(() => {
          setTimer((prevTimer) => prevTimer + 1);
        }, 1000);
      }
      else {
        trackMixpanelEvent(MixpanelEventName.START_RECORDING_BUTTON_CLICKED);

        if (!selectedDeviceId) {
          return;
        }

        setStatus(Status.RECORDING);

        // Dynamically set sampleRate based on system capabilities
        const { sampleRate } = audioCtx;
        const estimatedChannelCount = audioCtx.destination.channelCount || 1;

        // Start recording audio from the selected device
        streamRef.current = await navigator.mediaDevices.getUserMedia({
          audio: {
            deviceId: selectedDeviceId,
            sampleRate, // Use system-supported sample rate
            channelCount: estimatedChannelCount, // Keep it mono (adjust if needed)
            echoCancellation: false,
            noiseSuppression: false,
            autoGainControl: false,
          },
        });

        if (isFastAPIEnabled) {
          const firstMediaRecorder = new MediaRecorder(streamRef.current);
          const secondMediaRecorder = new MediaRecorder(streamRef.current);
          const thirdMediaRecorder = new MediaRecorder(streamRef.current);

          firstMediaRecorder.ondataavailable = (event) => {
            audioChunks.current.push(event.data);
          };

          secondMediaRecorder.ondataavailable = (event) => {
            handleRecordingUpload([event.data]);
          };

          thirdMediaRecorder.ondataavailable = (event) => {
            handleRecordingUpload([event.data]);
          };

          setMediaRecorders({
            first: firstMediaRecorder,
            second: secondMediaRecorder,
            third: thirdMediaRecorder,
          });
          firstMediaRecorder.start();
          startAndStop(getIntervals(secondMediaRecorder, thirdMediaRecorder));
        }
        else {
          const newMediaRecorder = new MediaRecorder(streamRef.current);

          newMediaRecorder.ondataavailable = (event) => {
            audioChunks.current.push(event.data);
          };
          newMediaRecorder.start();
          setMediaRecorders({ first: newMediaRecorder });

          // const res = await getSessionAuthToken();
          // const { token } = res;

          const speechConfig = SpeechConfig.fromSubscription(subscriptionKey, region);
          // const speechConfig = SpeechConfig.fromAuthorizationToken(token, region);
          speechConfig.speechRecognitionLanguage = speechRecognitionLanguage;
          speechConfig.setProfanity(ProfanityOption.Masked);

          // Convert the audio stream to a format that the SDK can understand
          const audioConfig = AudioConfig.fromStreamInput(streamRef.current);
          const newRecognizer = new SpeechRecognizer(speechConfig, audioConfig);

          newRecognizer.recognized = (s, e) => {
            if (e.result.reason === ResultReason.RecognizedSpeech) {
              setDisplayText((prev) => ({
                ...prev,
                [new Date().getTime()]: e.result.text,
              }));
            }
            else {
              updateStatusAsError();
              logSentryError(`Error recognizing speech - ${e.result.reason}`);
            }
          };

          newRecognizer.startContinuousRecognitionAsync();
          setRecognizer(newRecognizer);
        }

        // Start the timer
        timerInterval.current = setInterval(() => {
          setTimer((prevTimer) => prevTimer + 1);
        }, 1000);
      }

      if (streamRef.current) {
      // Analyze the audio stream to get the volume level
        const source = audioCtx.createMediaStreamSource(streamRef.current);
        const analyser = audioCtx.createAnalyser();
        source.connect(analyser);
        analyser.fftSize = 256;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const getVolume = () => {
          analyser.getByteFrequencyData(dataArray);
          const sum = dataArray.reduce((a, b) => a + b, 0);
          const average = sum / dataArray.length;
          setVolume(average);
          volumeInterval.current = setTimeout(() => {
            getVolume();
          }, 20);
        };

        getVolume();
      }
    }
    catch (error) {
      updateStatusAsError();
      logSentryError(`Error starting recording - ${error instanceof Error ? error.message : JSON.stringify(error)}`);
    }
  };

  const stopStream = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null; // Clear the stream reference
    }
  };

  async function stopRecording() {
    trackMixpanelEvent(MixpanelEventName.END_SESSION_BUTTON_CLICKED, {
      time: timer.time,
      session: destinationUrls?.sessionId ?? '',
    });
    stopStream();
    setStatus(Status.DEFAULT);
    setIsGeneratingTranscript(true);
    await getDestinationUrls();
  }

  const numberOfAudioDevices = audioInputDevices.filter(
    (device) => device.kind === 'audioinput' && device.deviceId,
  ).length;

  const handleClose = () => {
    setShowWarning(false);
  };

  if (status === Status.ERROR) {
    return (
      <NoteTakerWrapper setIsDrawerOpen={setIsDrawerOpen}>
        <Box display='flex' flexDirection='column' alignItems='center' my={6}>
          <Typography variant='h4' color='error'>Generation failed!</Typography>
          <Typography variant='body1' color={theme.custom.colors.lightTextSecondary} my={3}>
            Something went wrong while generating notes.
          </Typography>
        </Box>
      </NoteTakerWrapper>
    );
  }

  if (isGeneratingTranscript) {
    return (
      <NoteTakerWrapper>
        <Box display='flex' flexDirection='column' alignItems='center' my={6}>
          <Typography variant='h4'>Generating</Typography>
          <Typography variant='body1' color={theme.custom.colors.lightTextSecondary} my={3}>
            This can take up to 1 minute. Do not close the browser tab.
          </Typography>
          <CircularProgress size={100} />
        </Box>
      </NoteTakerWrapper>
    );
  }

  if (generatedNotes.sessionId && isDrawerOpen) {
    return (
      <Results
        generatedNotes={generatedNotes}
        isDrawerOpen={isDrawerOpen}
        metadata={metadata as GetMetadataResponse}
        isLoadingMetadata={isLoadingMetadata}
        setIsDrawerOpen={setIsDrawerOpen}
        setToastProps={setToastProps}
      />
    );
  }

  return (
    <NoteTakerWrapper setIsDrawerOpen={setIsDrawerOpen}>
      <Snackbar
        open={showWarning}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert severity='warning' sx={{ width: '100%' }} onClose={handleClose}>
          There is no internet connection. Please check your connection.
        </Alert>
      </Snackbar>
      <Box p={2} display='flex' flexDirection='column' alignItems='center'>
        <Box
          width='100%'
          display='flex'
          mb={3}
          maxWidth={500}
          flexDirection='column'
          alignItems='center'
        >
          <Typography variant='h4' mb={3}>
            {status === Status.DEFAULT ? 'Ready to record' : ''}
            {status === Status.PAUSED ? 'Recording paused' : ''}
            {status === Status.RECORDING ? 'Recording in progress' : ''}
          </Typography>
          {status !== Status.DEFAULT && (
          <Box display='flex' flexDirection='row' alignItems='flex-end' mb={4}>
            <Typography variant='h1' color={theme.custom.colors.primaryMain} fontWeight={500} lineHeight='100%' mr={2}>{timer.time}</Typography>
          </Box>
          )}
          {status !== Status.RECORDING && (
          <FormControl variant='outlined' sx={{ mb: 4.5 }} fullWidth>
            <InputLabel>Select audio input device</InputLabel>
            <Select
              value={selectedDeviceId || ''}
              onChange={(e) => setSelectedDeviceId(e.target.value as string)}
              label='Select audio input device'
              disabled={numberOfAudioDevices === 0 || status === Status.PAUSED}
            >
              {audioInputDevices.map((device) => (
                <MenuItem key={device.deviceId} value={device.deviceId}>
                  {device.label}
                </MenuItem>
              ))}
            </Select>
            {numberOfAudioDevices === 0 && isMicrophonePermissionDenied && (
            <Typography color='error' variant='body2' my={0.5}>
              Please enable microphone access in your browser settings
            </Typography>
            )}
          </FormControl>
          )}
          {status === Status.RECORDING ? (
            <Box display='flex' flexDirection='column' alignItems='center' mt={3}>
              <SilenceDetection volumeLevel={volume} threshold={12} />
              <Button
                sx={{ mt: 6 }}
                variant='outlined'
                onClick={() => setIsDrawerOpen(false)}
                fullWidth
                data-testid='view-patient-overview-button'
              >
                VIEW PATIENT DASHBOARD
              </Button>
              <Typography variant='body1' color={theme.custom.colors.lightTextSecondary} mt={1.5}>
                (recording will continue)
              </Typography>
            </Box>
          ) : (
            <Box display='flex' flexWrap='nowrap' justifyContent={status !== Status.DEFAULT ? 'space-between' : 'center'} width='100%' gap={2}>
              <Button
                variant={status === Status.PAUSED ? 'outlined' : 'contained'}
                startIcon={<RadioButtonChecked />}
                disabled={numberOfAudioDevices === 0 || !destinationUrls?.sessionId}
                onClick={startRecording}
                sx={{ width: status !== Status.DEFAULT ? '100%' : '50%' }}
              >
                {status === Status.PAUSED ? 'RESUME RECORDING' : 'START RECORDING'}
              </Button>
              {status !== Status.DEFAULT && (
              <Button
                variant={status === Status.PAUSED ? 'contained' : 'outlined'}
                onClick={stopRecording}
                disabled={!status}
                fullWidth
                data-testid='end-session-button'
              >
                END SESSION
              </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </NoteTakerWrapper>
  );
}
