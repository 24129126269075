import React, { useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the Quill snow theme CSS
import Theme from 'theme';

interface IHtmlPreviewProps {
  htmlContent: string;
  onPlainTextSave?: (plainText: string) => void;
}

export function HtmlPreview(
  { htmlContent, onPlainTextSave }: IHtmlPreviewProps,
) {
  const quillRef = useRef<ReactQuill | null>(null);

  useEffect(() => {
    if (quillRef.current) {
      const plainText = quillRef.current.getEditor().getText();
      if (onPlainTextSave) {
        onPlainTextSave(plainText);
      }
    }
  }, [htmlContent, onPlainTextSave]);

  return (
    <>
      <ReactQuill
        ref={quillRef}
        value={htmlContent}
        readOnly
        theme='bubble'
        modules={{ toolbar: false,
          clipboard: {
            matchVisual: false,
          } }}
        className='html-preview'
      />
      <style>
        {`
        .html-preview .ql-editor {
          padding: 0;
          overflow-y: auto;
        }
        .html-preview .ql-editor h6 {
          font-size: 1.26rem;
          font-weight: 700;
          line-height: 1.6;
          letter-spacing: 0.15px;
        }
        .html-preview .ql-editor p {
          font-size: 1.01rem;
          color: ${Theme.custom.colors.lightTextSecondary};
        }
        .html-preview .ql-editor strong {
          font-weight: 700;
          font-size: 1.01rem;
          color: ${Theme.custom.colors.lightTextPrimary};
        }
      `}
      </style>
    </>
  );
}

function TextEditor({ value, setValue }: { value: string, setValue: (v: string) => void }) {
  const handleChange = (v: string) => {
    setValue(v);
  };

  return (
    <div className='editor-container'>
      <ReactQuill
        value={value}
        onChange={handleChange}
        theme='snow'
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ align: [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ color: [] }, { background: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            ['blockquote', 'code-block'],
            ['clean'],
          ],
          clipboard: {
            matchVisual: false,
          },
        }}
        formats={[
          'header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'align', 'color', 'background', 'script', 'blockquote', 'code-block',
        ]}
        className='text-editor'
      />
      <style>
        {`
        .editor-container .ql-editor {
          overflow-y: auto;
        }
        .editor-container .ql-editor h6 {
          font-size: 1.26rem;
          font-weight: 700;
          line-height: 1.6;
          letter-spacing: 0.15px;
        }
        .editor-container .ql-editor p {
          font-size: 1.01rem;
          color: ${Theme.custom.colors.lightTextSecondary};
        }
        .editor-container .ql-editor strong {
          font-size: 1.01rem;
          font-weight: 700;
          color: ${Theme.custom.colors.lightTextPrimary};
        }
      `}
      </style>
    </div>
  );
}

export default TextEditor;
