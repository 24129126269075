import React, { useContext, useEffect } from 'react';
import { Pause, RadioButtonChecked } from '@mui/icons-material';
import { Drawer, Button } from '@mui/material';
import NoteTaker from 'pages/Dashboard/components/SessionRecording/NoteTaker';
import Consent from 'pages/Dashboard/components/SessionRecording/Consent';
import { Status } from 'pages/Dashboard/types';
import { trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';
import { formatTime } from 'utils/dateUtils';
import SilenceDetection from 'pages/Dashboard/components/SessionRecording/SilenceDetection';
import { ToastProps } from 'components/Common/Toast';
import { PatientCtx } from 'pages/Dashboard/constants';
import useConfirm from 'hooks/use-confirm';

interface IRecordSessionProps {
  fetchSessions: ({ offset }: {offset: number}) => void;
  setToastProps: React.Dispatch<React.SetStateAction<ToastProps | null>>;
}

function RecordSession({
  fetchSessions,
  setToastProps,
}: IRecordSessionProps) {
  const { ConfirmDialog, showConfirm } = useConfirm();
  const { isEditingNotes } = useContext(PatientCtx);

  const { recordingStatus, setCb, setRecordingStatus } = useContext(PatientCtx);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [isConsentGiven, setIsConsentGiven] = React.useState(false);
  const [timer, setTimer] = React.useState<number>(0);
  const [volume, setVolume] = React.useState<number>(0);
  const [isGeneratingTranscript, setIsGeneratingTranscript] = React.useState(false);

  const formattedTime = formatTime(timer);

  const handleRecordSessionClick = React.useCallback(() => {
    setRecordingStatus((prev) => {
      if (prev === Status.RECORDING) {
        trackMixpanelEvent(MixpanelEventName.PAUSE_RECORDING_BUTTON_CLICKED, {
          time: formattedTime.time,
        });
        return Status.PAUSED;
      }

      trackMixpanelEvent(MixpanelEventName.RECORD_SESSION_BUTTON_CLICKED);
      return prev;
    });

    setIsDrawerOpen(true);
  }, [formattedTime.time, setRecordingStatus]);

  useEffect(() => {
    setCb(() => handleRecordSessionClick);
  }, [setCb, handleRecordSessionClick]);

  const updateStatus = React.useCallback(
    (newStatus: Status) => {
      setRecordingStatus(newStatus);
    },
    [setRecordingStatus],
  );

  const handleIUnderstandClicked = () => {
    trackMixpanelEvent(MixpanelEventName.RECORD_SESSION_CONSENT_BUTTON_CLICKED);
    setIsConsentGiven(true);
  };

  const onClose = () => {
    if (isGeneratingTranscript) {
      return;
    }

    if (isEditingNotes) {
      showConfirm({
        message: 'Are you sure you want to discard the changes?',
        onConfirm: () => {
          setIsDrawerOpen(false);
        },
      });
    }
    else {
      setIsDrawerOpen(false);
    }
  };

  return (
    <>
      <ConfirmDialog />
      <Drawer
        anchor='right'
        open={isDrawerOpen}
        onClose={onClose}
        PaperProps={{
          sx: {
            maxWidth: 'lg',
            width: '100%',
            overflow: 'hidden',
            ...(!isConsentGiven ? { display: 'flex',
              flexDirection: 'column',
              alignItems: 'center' } : {}),
          },
        }}
        keepMounted
      >
        {!isConsentGiven ? (
          <Consent
            handleIUnderstandClicked={handleIUnderstandClicked}
            setIsDrawerOpen={setIsDrawerOpen}
          />
        ) : (
          <NoteTaker
            status={recordingStatus}
            timer={formattedTime}
            isDrawerOpen={isDrawerOpen}
            volume={volume}
            isGeneratingTranscript={isGeneratingTranscript}
            setIsGeneratingTranscript={setIsGeneratingTranscript}
            setToastProps={setToastProps}
            setStatus={updateStatus}
            setTimer={setTimer}
            setVolume={setVolume}
            setIsDrawerOpen={setIsDrawerOpen}
            fetchSessions={fetchSessions}
          />
        )}
      </Drawer>
      <Button
        variant='contained'
        color={recordingStatus === Status.DEFAULT ? 'primary' : 'error'}
        startIcon={recordingStatus === Status.DEFAULT ? <RadioButtonChecked /> : <Pause />}
        onClick={handleRecordSessionClick}
        data-testid='record-session-button'
        sx={{ mr: recordingStatus === Status.RECORDING ? 2 : 0 }}
      >
        {recordingStatus === Status.DEFAULT
          ? 'RECORD SESSION'
          : formattedTime.time}
      </Button>
      {recordingStatus === Status.RECORDING && (
        <SilenceDetection volumeLevel={volume} threshold={12} />
      )}
    </>
  );
}

export default RecordSession;

